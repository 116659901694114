import React from 'react';
import PropTypes from 'prop-types';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import MDXContent from 'components/MDXContent';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventLink from './EventLink';
import CausalSplitter from './CausalSplitter';

const components = { EventLink };
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  card: {
    width: '100%',
    padding: '20px',
    textAlign: 'left'
  },
  dot: {
    width: '38px',
    height: '38px'
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500,
      marginBlockStart: '0'
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  }
}));

function CausalTimeline(props) {
  const { causalChainSummary } = props;
  const classes = useStyles();

  const steps = CausalSplitter(causalChainSummary);

  return (
    <Timeline align="alternate">
      {steps.map((step, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot className={classes.dot} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Card className={classes.card}>
              <MDXContent components={components} content={`## ${step}`} />
            </Card>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
CausalTimeline.propTypes = {
  causalChainSummary: PropTypes.string.isRequired
};

export default CausalTimeline;
