/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AlbTable from 'components/AlbTable';
import CustomFooter from 'components/TablePagination';
import colors from 'util/colors';

/**
 * @summary Renders AA Segments linktokens table
 * @name AdobeAnalyticsSegmentsSearchTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.tableColumns - column data
 * @param {String} props.tableData - table row data
 * @return {Object} - React JSX
 */
const AdobeAnalyticsSegmentsSearchTable = props => {
  const { tableColumns, tableData } = props;

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(tableData.length);
  }, [tableData]);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;
    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }
    setPage(currentPage);
  }, [page, rowsPerPage]);

  const tableThemeOverrides = {
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap',

        '&:nth-child(-n+2)': {
          width: 'unset'
        }
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#f5f5f5'
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colors.athensGray
        }
      }
    }
  };

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    sort: false,
    pagination: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    customFooter,
    count,
    textLabels: {
      body: {
        noMatch: 'No segments found'
      }
    }
  };

  return (
    <AlbTable
      tableData={tableData}
      tableColumns={tableColumns}
      tableOptions={tableOptions}
      tableThemeOverrides={tableThemeOverrides}
    />
  );
};

AdobeAnalyticsSegmentsSearchTable.propTypes = {
  tableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default AdobeAnalyticsSegmentsSearchTable;
