//
// Tables used for OAuth configs
//

// Facebook
// these scopes will be requested by the client.
// You must be approved to request them.
import apolloClientHelper from 'middleware/api';
import { gql } from '@apollo/client';
import { showToast } from 'contexts/ToastContext';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';

import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  MailchimpLogo,
  TwitterLogo,
  YouTubeLogo,
  GoogleLogo,
  SalesforceLogo,
  TikTokLogo
} from 'util/assets';

export const fbScopes = [
  'business_management',
  'email',
  'instagram_basic',
  'instagram_manage_insights',
  'instagram_content_publish',
  'pages_manage_metadata',
  'pages_read_engagement',
  'pages_show_list',
  'pages_read_user_content',
  'pages_manage_posts',
  'pages_manage_engagement',
  'public_profile',
  'read_insights'
];

// LinkedIn
/*
      Note that per https://developer.linkedin.com/docs/oauth2-legacy these scopes should be optional and use 
      the application's default scopes, but in practice, the LinkedIn OAuth call was failing if not specified;
      manually added the scopes from the production app @ https://www.linkedin.com/developers/apps/49506966/auth
      */

export const lnScopes = [
  'r_emailaddress',
  'r_ads',
  'w_organization_social',
  'rw_ads',
  'r_basicprofile',
  'r_liteprofile',
  'r_ads_reporting',
  'r_organization_social',
  'rw_organization_admin',
  'w_member_social'
];

/* These cover youtube access scopes. It MUST match the list server side! */
export const ytScopes = [
  // Make sure we can get the user's info from google
  'https://www.googleapis.com/auth/userinfo.profile',
  // access youtube
  // 'https://www.googleapis.com/auth/youtube',
  // View your YouTube account
  'https://www.googleapis.com/auth/youtube.readonly',
  // See a list of your current active channel members, their current level, and when they became a member
  // 'https://www.googleapis.com/auth/youtube.channel-memberships.creator',
  // upload youtube videos
  // 'https://www.googleapis.com/auth/youtube.upload',
  // permits access to comments
  // 'https://www.googleapis.com/auth/youtube.force-ssl',
  // View YouTube Analytics reports for your YouTube content. This scope provides access to user activity metrics, like view counts and rating counts.
  'https://www.googleapis.com/auth/yt-analytics.readonly'
  // View YouTube Analytics monetary reports for your YouTube content. This scope provides access to user activity metrics and to estimated revenue and ad performance metrics.
  // 'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
  // get data about YouTube partner accounts,
  // 'https://www.googleapis.com/auth/youtubepartner'
  // Retrieve the auditDetails part in a channel resource.
  // 'https://www.googleapis.com/auth/youtubepartner-channel-audit'
];

export const gaScopes = [
  // Make sure we can get the user's info from google
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/analytics.readonly'
];

/**
 * Salesforce scopes
 * https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_tokens_scopes.htm&type=5
 */
export const sfScopes = [
  'cdp_query_api', // Allows ANSI SQL queries of Customer Data Platform data on behalf of the user.
  'api', // Allows access to the current, logged-in user’s account using APIs, such as REST API and Bulk API 2.0
  'refresh_token', // Allows a refresh token to be returned when the requesting client is eligible to receive one.
  'offline_access' // Allows offline access
];

/**
 * A supported OAuth provider. All varaibles are required unless otherwise specified.
 * @typedef OAuthConfigType
 * @type {Object}
 * @property {String} name - The name of the network displayed to the user
 * @property {Array} logos - One or More logos displayed to the User in the OAuthButton
 * @property {String} consentScreenURL - The URL to the consent screen
 * @property {String} callbackURL - The URL to the callback
 * @property {String} alembicPrimaryType - The primary type of the alembic linktoken (e.g. FB_PROFILE) which is the user's main account "profile" on the service.
 * @property {Array} alembicTypes - The Linktoken types associated with this provider.
 * @property {String} consentURL - The URL to the consent screen
 * @property {String} returnCodeVar - The name of the variable in the query string that contains the return code
 * @property {String} returnVerifierVar - (optional) The name of the variable in the query string that contains the return verifier
 */

/**
 * List of providers for the link accounts page
 * @type {OAuthConfigType}
 */
export const OAuthConfigs = {
  // facebook, instagram
  facebook: {
    // FB_PROFILE is the only one that we should be including
    name: 'Facebook / Instagram',
    logos: [FacebookLogo, InstagramLogo],
    alembicPrimaryType: LINKTOKEN_TYPE_ENUM.FB_PROFILE,
    alembicTypes: [
      LINKTOKEN_TYPE_ENUM.FB_PROFILE,
      LINKTOKEN_TYPE_ENUM.FB_PAGE,
      LINKTOKEN_TYPE_ENUM.INSTAGRAM
    ],
    callbackURL: process.env.WEB_URL + process.env.FB_CALLBACK_PATH,
    consentScreenURL: `https://www.facebook.com/v6.0/dialog/oauth?client_id=${
      process.env.FB_APP_ID
    }&scope=${fbScopes.join()}&response_type=code,granted_scopes&redirect_uri=${encodeURIComponent(
      process.env.WEB_URL + process.env.FB_CALLBACK_PATH
    )}`,
    returnCodeVar: 'code'
  },
  // twitter sucks because the consent url needs a rotating key.
  twitter: {
    // TWITTER
    // requries appending of ?oauth_token= and a recent-ish twitter request token
    // pulled via GET_TWITTER_REQTOKE
    name: 'Twitter',
    logos: [TwitterLogo],
    alembicPrimaryType: LINKTOKEN_TYPE_ENUM.TWITTER,
    alembicTypes: [LINKTOKEN_TYPE_ENUM.TWITTER],
    callbackURL: process.env.WEB_URL + process.env.TWITTER_CALLBACK_PATH,
    consentScreenURL: 'https://api.twitter.com/oauth/authenticate?oauth_token=',
    returnCodeVar: 'oauth_token',
    returnVerifierVar: 'oauth_verifier'
  },
  linkedin: {
    // LINKEDIN_PROFILE
    name: 'LinkedIn',
    logos: [LinkedinLogo],
    alembicPrimaryType: LINKTOKEN_TYPE_ENUM.LINKEDIN_PROFILE,
    alembicTypes: [LINKTOKEN_TYPE_ENUM.LINKEDIN_PROFILE, LINKTOKEN_TYPE_ENUM.LINKEDIN_COMPANY],
    callbackURL: process.env.WEB_URL + process.env.LINKEDIN_CALLBACK_PATH,
    consentScreenURL: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
      process.env.LINKEDIN_CLIENT_ID
    }&redirect_uri=${process.env.WEB_URL +
      process.env.LINKEDIN_CALLBACK_PATH}&scope=${lnScopes.join()}`,
    returnCodeVar: 'code'
  },
  mailchimp: {
    // MAILCHIMP
    name: 'Mailchimp',
    logos: [MailchimpLogo],
    alembicPrimaryType: 'MAILCHIMP',
    alembicTypes: ['MAILCHIMP'],
    callbackURL: process.env.WEB_URL + process.env.MAILCHIMP_CALLBACK_PATH,
    consentScreenURL: `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${process.env.MAILCHIMP_CLIENT_ID}`,
    returnCodeVar: 'code'
  },
  google: {
    // GOOGLE_PROFILE
    name: 'Google Analytics',
    logos: [GoogleLogo],
    alembicPrimaryType: LINKTOKEN_TYPE_ENUM.GOOGLE_PROFILE,
    alembicTypes: [LINKTOKEN_TYPE_ENUM.GOOGLE_PROFILE, LINKTOKEN_TYPE_ENUM.GOOGLE_ANALYTICS],
    callbackURL: process.env.WEB_URL + process.env.GA_CALLBACK_PATH,
    // Google wants our client ID first or they won't approve us! ugh.
    consentScreenURL: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
      process.env.GA_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      process.env.WEB_URL + process.env.GA_CALLBACK_PATH
    )}&scope=${encodeURIComponent(
      gaScopes.join(' ')
    )}&response_type=code&access_type=offline&prompt=consent&include_granted_scopes=true&state=state_parameter_passthrough_value`,
    returnCodeVar: 'code'
  },
  youtube: {
    name: 'YouTube',
    logos: [YouTubeLogo],
    alembicPrimaryType: LINKTOKEN_TYPE_ENUM.YOUTUBE, // really needs to go on the google account! not here!
    alembicTypes: [LINKTOKEN_TYPE_ENUM.YOUTUBE],
    // YOUTUBE account type, but it should be underneath a GOOGLE_PROFILE.
    // Google wants our client ID first or they won't approve us! ugh.
    callbackURL: process.env.WEB_URL + process.env.YOUTUBE_CALLBACK_PATH,
    consentScreenURL: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
      process.env.YOUTUBE_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      process.env.WEB_URL + process.env.YOUTUBE_CALLBACK_PATH
    )}&scope=${encodeURIComponent(
      ytScopes.join(' ')
    )}&response_type=code&access_type=offline&prompt=consent&include_granted_scopes=true&state=state_parameter_passthrough_value`,
    returnCodeVar: 'code'
  },
  salesforce: {
    // SALESFORCE
    name: 'Salesforce',
    logos: [SalesforceLogo],
    alembicPrimaryType: LINKTOKEN_TYPE_ENUM.SALESFORCE,
    alembicTypes: [LINKTOKEN_TYPE_ENUM.SALESFORCE],
    callbackURL: process.env.WEB_URL + process.env.SALESFORCE_CALLBACK_PATH,
    consentScreenURL: `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
      process.env.SALESFORCE_CLIENT_ID
    }&redirect_uri=${process.env.WEB_URL +
      process.env.SALESFORCE_CALLBACK_PATH}&scope=${sfScopes.join(' ')}`,
    returnCodeVar: 'code'
  },
  tiktok: {
    name: 'TikTok',
    logos: [TikTokLogo],
    alembicPrimaryType: LINKTOKEN_TYPE_ENUM.TIKTOK,
    alembicTypes: [LINKTOKEN_TYPE_ENUM.TIKTOK],
    callbackURL: process.env.WEB_URL + process.env.TIKTOK_CALLBACK_PATH,
    returnCodeVar: 'code'
  }
};

const GET_TWITTER_REQTOKEN = gql`
  mutation {
    getTwitterReqToken {
      oauth_token
    }
  }
`;

/**
 * Returns the consent URL for a particular network handling special requests if
 * needed. In the case of Twitter, fetches the request verifier token first.
 * @param {Object} config
 * @returns String
 */
export const getConsentURL = config => {
  if (config.alembicPrimaryType === 'TWITTER') {
    try {
      return apolloClientHelper(GET_TWITTER_REQTOKEN, {}, true).then(result => {
        return config.consentScreenURL + result.payload.data.getTwitterReqToken.oauth_token;
      });
    } catch (error) {
      showToast(error, 'error');
      return '';
    }
  }

  // we'll just pass the user along to the new URL
  return config.consentScreenURL;
};

/**
 * return the configuration object for a given linktoken type
 *
 * @param {String} linktokenType the type of the linktoken to return for
 */
export const getConfigObject = linktokenType => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(OAuthConfigs)) {
    if (OAuthConfigs[key].alembicTypes.includes(linktokenType)) {
      return OAuthConfigs[key];
    }
  }
  return null;
};

/** Given the current URL, return the config object
 * @param {String} currentURL the current URL
 */
export const getOAuthConfigForURL = currentURL => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(OAuthConfigs)) {
    if (currentURL.includes(OAuthConfigs[key].callbackURL)) {
      return OAuthConfigs[key];
    }
  }
  return null;
};
