import PropTypes from 'prop-types';
import SIMPLE_SOCIAL_TYPE_ENUM from './getSimpleSocialTypeEnum';
/**
 * @method
 * @summary This function converts a complex social type to a simple one
 * @name getSimpleSocialType
 * @param {string} type - The type of the social
 * @return {string} - Returns a simple social type
 */

const getSimpleSocialType = (type, useSimple = false) => {
  let simpleType = '';

  const formattedType = type.trim().toLowerCase();

  if (
    formattedType.includes('facebook') ||
    formattedType.startsWith('fb_') ||
    formattedType === 'fb'
  ) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.FACEBOOK;
  } else if (formattedType.includes('instagram') || formattedType === 'ig') {
    if (formattedType.includes('story')) {
      simpleType = SIMPLE_SOCIAL_TYPE_ENUM.INSTAGRAM_STORY;
    } else if (formattedType.includes('reel')) {
      simpleType = SIMPLE_SOCIAL_TYPE_ENUM.INSTAGRAM_REEL;
    } else {
      simpleType = SIMPLE_SOCIAL_TYPE_ENUM.INSTAGRAM;
    }
  } else if (formattedType.includes('twitter') || formattedType === 'tw') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.TWITTER;
  } else if (formattedType.includes('linkedin') || formattedType === 'li') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.LINKEDIN;
  } else if (formattedType.includes('youtube') || formattedType === 'yt') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.YOUTUBE;
  } else if (formattedType.includes('tiktok') || formattedType === 'tt') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.TIKTOK;
  } else if (formattedType.startsWith('google_play') && !formattedType.endsWith('_app')) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_PLAY_CONSOLE;
  } else if (
    (formattedType.startsWith('google_') || formattedType === 'ga') &&
    !formattedType.endsWith('_app')
  ) {
    if (useSimple) {
      return SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE;
    }

    if (formattedType.includes('analytics')) {
      simpleType = SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_ANALYTICS;
    } else if (formattedType.includes('webproperty')) {
      simpleType = SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_WEB;
    } else if (formattedType.includes('view')) {
      simpleType = SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_VIEW;
    } else {
      simpleType = SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE;
    }
  } else if (formattedType.startsWith('sa_') || formattedType === 'sa') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.SOCIAL_ANIMAL;
    // } else if (formattedType.startsWith('bc')) {
    //   simpleType = 'broadcast';
    // TODO: Uncomment when we break up tv and radio.
  } else if (formattedType.startsWith('bc_tv') || formattedType.startsWith('nc_bc_tv')) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.TV;
  } else if (formattedType.startsWith('bc_radio') || formattedType.startsWith('nc_bc_radio')) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.RADIO;
  } else if (formattedType.startsWith('pc') || formattedType.startsWith('nc_pc')) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.PODCAST;
  } else if (formattedType.startsWith('aa_') || formattedType === 'aa') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS;
  } else if (formattedType.startsWith('app_store') && !formattedType.endsWith('_app')) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.APPLE_APP_STORE_CONNECT;
  } else if (
    (formattedType.startsWith('app_store') && formattedType.endsWith('_app')) ||
    formattedType.startsWith('ap_')
  ) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.APP_STORE_CONNECT_APP;
  } else if (
    (formattedType.startsWith('google_play') && formattedType.endsWith('_app')) ||
    formattedType.startsWith('go_')
  ) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_PLAY_CONSOLE_APP;
  } else if (formattedType.startsWith('ns') || formattedType === 'ns') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.NASDAQ;
  } else if (formattedType.startsWith('sl_') || formattedType === 'sl') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.SHORTLINK;
  } else if (formattedType.includes('salesforce') || formattedType.startsWith('sf_')) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.SALESFORCE;
  } else if (formattedType.startsWith('sb_') || formattedType === 'sb') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.STARBUCKS;
  } else if (formattedType.startsWith('sp_')) {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.STARBUCKS_PLACER;
  } else if (formattedType.startsWith('me_') || formattedType === 'af') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.AMPLIFON;
  } else if (formattedType.startsWith('re_') || formattedType === 're') {
    simpleType = SIMPLE_SOCIAL_TYPE_ENUM.REPUBLIC;
  }

  return simpleType;
};

getSimpleSocialType.propTypes = {
  type: PropTypes.string.isRequired
};

export default getSimpleSocialType;
