import { DETECTED_EVENT_TYPE, LINKTOKEN_EVENT_TYPE } from 'util/detectedEvents';
import moment from 'moment';

/**
 * @method
 * @summary Implements useContext() passed down as prop and depending on event type, displays corresponding data in a proper drawer.
 * @name openDashboardDrawer
 * @prop {Object} event - full event object, either detected_event or linktoken_event
 * @prop {Object} drawerContext - DashboardIntelligenceDrawerContext that opens the ConversionOutlierDrawerView and SourceSpikeDrawerView
 * @prop {Object} socialPostDrawerContext - PostDrawerViewContext that opens the PostDrawerView
 * @prop {Object} history - react router history object for routing to multi-post resurgences dashboard
 */

const openDashboardDrawer = (event, drawerContext, socialPostDrawerContext) => {
  if (
    event.type === DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE ||
    event.type === LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA3 ||
    event.type === LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA4
  ) {
    drawerContext.toggleDrawer({ isDrawerOpen: true, event, isSourceSpike: true });
  }

  if (
    event.type === LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA3 ||
    event.type === LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA4 ||
    event.type === DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL
  ) {
    drawerContext.toggleDrawer({ isDrawerOpen: true, event, isConversionOutlier: true });
  }

  if (
    event.type === LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA3 ||
    event.type === LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA4
  ) {
    drawerContext.toggleDrawer({ isDrawerOpen: true, event, isPageSpike: true });
  }

  if (event.type === 'CORRELATION') {
    drawerContext.toggleDrawer({ isDrawerOpen: true, event, isCorrelation: true });
  }

  if (
    event.type === DETECTED_EVENT_TYPE.IM_POST_RESURGE ||
    event.type === DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE ||
    event.type === DETECTED_EVENT_TYPE.EG_POST_RESURGE ||
    event.type === DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE ||
    // event.type === DETECTED_EVENT_TYPE.FL_POST_RESURGE ||
    // event.type === DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE ||
    event.type === LINKTOKEN_EVENT_TYPE.RESURGE_POST ||
    event.type === LINKTOKEN_EVENT_TYPE.INITIAL_SURGE
  ) {
    const { startDate: surgeStartDate, endDate: surgeEndDate } = event; // surge start and end dates.

    // eslint-disable-next-line camelcase
    const { id, linked_account_id } = event.campaignEvent;

    // we want to display graphs with 3 additional days around the surge.
    const startDate = moment(surgeStartDate)
      .subtract(3, 'd')
      .toISOString();
    const endDate = moment(surgeEndDate)
      .add(3, 'd')
      .toISOString();

    const isInitialSurge =
      event.type === DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE ||
      event.type === DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE ||
      // event.type === DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE ||
      event.type === LINKTOKEN_EVENT_TYPE.INITIAL_SURGE;

    const isResurgence =
      event.type === LINKTOKEN_EVENT_TYPE.RESURGE_POST ||
      event.type === DETECTED_EVENT_TYPE.IM_POST_RESURGE ||
      event.type === DETECTED_EVENT_TYPE.EG_POST_RESURGE;
    // event.type === DETECTED_EVENT_TYPE.FL_POST_RESURGE;

    socialPostDrawerContext.toggleDrawer(
      true,
      id,
      null,
      linked_account_id,
      startDate,
      endDate,
      isResurgence,
      surgeStartDate,
      surgeEndDate,
      isInitialSurge
    );
  }

  if (event.type === LINKTOKEN_EVENT_TYPE.RESURGE_MULTIPOST) {
    drawerContext.toggleDrawer({ isDrawerOpen: true, event, isMultiPostDetection: true });
  }

  if (
    [
      // Social
      // DETECTED_EVENT_TYPE.IM_POST_RESURGE above
      DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE,
      // DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE above
      DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE,
      // DETECTED_EVENT_TYPE.EG_POST_RESURGE above
      DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE,
      // DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE above
      DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE,
      // DETECTED_EVENT_TYPE.FL_POST_RESURGE above
      // DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE,
      // DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE above
      // DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE,

      // Web: Google Analytics
      // DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE above
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE,
      DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH,
      DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY,
      DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY,
      DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME,
      DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD,
      DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM,
      DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL,
      DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL,

      // Web: Adobe Analytics
      DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE,
      DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH,
      DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD,
      DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH,
      DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH,
      DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL,
      DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT,
      DETECTED_EVENT_TYPE.AA_UNIQUE_VISITORS,

      // Web: Shortlink
      DETECTED_EVENT_TYPE.SL_CLICKS,

      DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS,

      // Conversion: NASDAQ
      DETECTED_EVENT_TYPE.NS_FUND_FLOW,

      // Conversion: Google Analytics
      // DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL above
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM,
      DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION,
      DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION,
      DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE,
      DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME,
      DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL,
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING,
      DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME,

      // Conversion: Apple App Store
      DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE,
      DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE,
      DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE,
      DETECTED_EVENT_TYPE.GO_INSTALLS,

      // Conversion: Google Play Store
      DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT,
      DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS,

      // Conversion: Adobe Analytics
      DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL,
      DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE,
      DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_MARKETING_CHANNEL,
      DETECTED_EVENT_TYPE.AA_CALLS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.AA_CALLS_BY_MARKETING_CHANNEL,

      // Conversion: Salesforce
      DETECTED_EVENT_TYPE.SF_LEADS_LEADS,
      DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES,
      DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE,
      DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM,
      DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE,
      DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE,
      DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN,

      // Conversion: Starbucks
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1,
      DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2,
      DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE,
      DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1,
      DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2,
      DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE,

      // Conversion: Amplifon
      DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_STATE,
      DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_SEGMENT,
      DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_STATE,
      DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_SEGMENT,
      DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE,
      DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT,
      DETECTED_EVENT_TYPE.ME_QUANTITY_BY_STATE,
      DETECTED_EVENT_TYPE.ME_QUANTITY_BY_SEGMENT,

      // Conversion: Republic
      DETECTED_EVENT_TYPE.RE_REVENUE_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.RE_REVENUE_BY_SOURCE,
      DETECTED_EVENT_TYPE.RE_REVENUE_BY_INVESTMENT_INDUSTRY,
      DETECTED_EVENT_TYPE.RE_REVENUE_BY_INVESTOR_TYPE,
      DETECTED_EVENT_TYPE.RE_QUANTITY_BY_CAMPAIGN,
      DETECTED_EVENT_TYPE.RE_QUANTITY_BY_SOURCE,
      DETECTED_EVENT_TYPE.RE_QUANTITY_BY_INVESTMENT_INDUSTRY,
      DETECTED_EVENT_TYPE.RE_QUANTITY_BY_INVESTOR_TYPE,

      // Narratives
      DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES,
      DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL,
      DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL,
      DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS
    ].includes(event.type)
  ) {
    drawerContext.toggleDrawer({ event, isDrawerOpen: true, isDetectedEvent: true });
  }

  if (
    [
      DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS,
      DETECTED_EVENT_TYPE.BC_TV_MENTIONS,
      DETECTED_EVENT_TYPE.BC_TV_MENTIONS_WITH_VIEWERSHIP,
      DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP,
      DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
      DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS_WITH_AUDIENCE,
      DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS,
      DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE,
      DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE,
      DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT,
      DETECTED_EVENT_TYPE.SA_TOTAL_SHARES
    ].includes(event.type)
  ) {
    drawerContext.toggleDrawer({
      isDrawerOpen: true,
      event,
      isThirdPartyMedia: true
    });
  }
};

export default openDashboardDrawer;
