import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, Typography, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  GET_GOOGLE_ANALYTICS_FILTER,
  UPDATE_GOOGLE_ANALYTICS_FILTER,
  CREATE_GOOGLE_ANALYTICS_FILTER_LINK
} from 'gql/linktoken';
import AlbError from 'components/AlbError';
import { showToast } from 'contexts/ToastContext';
import { parseQueryString, goToRoute } from 'util/routesHelpers';
import { LinkAccountsPath } from 'util/paths';
import isValidJson from 'util/isValidJson';

/**
 * @method
 * @summary This component contains a form to add/edit a Google Analytics filter expression for the current account
 * @name LinkGoogleAnalyticsFilter
 * @return {Object} - React JSX
 */
const LinkGoogleAnalyticsFilterForm = props => {
  const { match, history, location } = props;
  const [inputValue, setInputValue] = useState('');
  const [updateError, setUpdateError] = useState(null);
  const [account, setAccount] = useState('');
  const [action, setAction] = useState('');
  const { containerId } = match?.params;

  // after component is mount, parse the query string and update local state
  useEffect(() => {
    if (location?.search) {
      setAccount(parseInt(parseQueryString('account', location), 10));
      setAction(parseQueryString('action', location)[0]);
    }
  }, []);

  const { loading: filterLoading, data: filterData } = useQuery(GET_GOOGLE_ANALYTICS_FILTER, {
    variables: {
      linktokenId: account
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (filterData) {
      const filterExpression = JSON.stringify(
        filterData.getGoogleAnalyticsFilter.ga_filter_expression,
        null,
        2
      );
      setInputValue(filterExpression);
    }
  }, [filterData]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidJson', value => isValidJson(value));
  }, []);

  const [updateGoogleAnalyticsFilter] = useMutation(UPDATE_GOOGLE_ANALYTICS_FILTER, {
    onError: setUpdateError,
    onCompleted: result => {
      showToast(
        // eslint-disable-next-line camelcase
        `Filter expression for ${result?.updateGoogleAnalyticsFilter.remote_name} has been updated.`,
        'success'
      );
      goToRoute(LinkAccountsPath, history);
    }
  });

  const [createGoogleAnalyticsFilterLink] = useMutation(CREATE_GOOGLE_ANALYTICS_FILTER_LINK, {
    onError: setUpdateError,
    onCompleted: result => {
      showToast(
        // eslint-disable-next-line camelcase
        `Filter expression for ${result?.createGoogleAnalyticsFilterLink?.token?.remote_name} has been created.`,
        'success'
      );
      goToRoute(LinkAccountsPath, history);
    }
  });

  const saveJson = () => {
    if (action === 'update') {
      updateGoogleAnalyticsFilter({
        variables: {
          linktokenId: account,
          gaFilterExpression: JSON.parse(inputValue.trim()),
          containerId
        },
        fetchPolicy: 'no-cache'
      });
    } else {
      createGoogleAnalyticsFilterLink({
        variables: {
          linktokenId: account,
          gaFilterExpression: JSON.parse(inputValue.trim())
        },
        fetchPolicy: 'no-cache'
      });
    }
  };

  const handleJsonInputChange = e => {
    setInputValue(e.target.value);
  };

  const formTitle = action === 'update' ? 'Edit Filter Expression' : 'Link Filter Expression';
  const formButtonText = action === 'update' ? 'Update' : 'Link';

  return (
    <>
      {updateError && <AlbError toast error={updateError} />}
      <ValidatorForm onSubmit={saveJson}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">{formTitle}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              size="medium"
              variant="outlined"
              multiline
              rows={20}
              placeholder="Add Google Analytics Filter Expression JSON"
              value={inputValue === 'null' ? '' : inputValue}
              onChange={e => handleJsonInputChange(e)}
              validators={['isValidJson']}
              errorMessages={['Invalid JSON']}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={filterLoading}
            >
              {formButtonText}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

LinkGoogleAnalyticsFilterForm.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired
};

export default withRouter(LinkGoogleAnalyticsFilterForm);
