import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { CAUSAL_CHAINS_SUMMARY } from 'gql/causalChainsSummary';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { LogoColored } from 'util/assets';
import { useReactToPrint } from 'ReactToPrint';

import { Print } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import StayTuned from './StayTuned';

import DateView from './DateView';
import SummaryOfSummaries from './SummaryOfSummaries';

const setPrintStyling = () => {
  return `@page { 
    margin: 50px 40px 50px 40px !important; 
  }
  .printTitle {
    @media print {
      font-size: 20px;
    }
  }
  .printRightSide {
    @media print {
      position: relative;
      bottom: 60px;
    }
  }
  .printLogo {
    display: none;

    @media print {
      display: block;
    }
  }
  .printBtn {
    display: block;

    @media print {
      display: none;
    }
  }
  `;
};

const useStyles = makeStyles(() => ({
  topMatter: {
    justifyContent: 'space-between'
  },
  title: {
    lineHeight: '1.2',
    marginBottom: '40px',
    marginTop: '0px'
  },
  rightSide: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  printButton: {
    color: '#000000'
  }
}));

const InsightsTabPanel = props => {
  const { timeSlice } = props;
  const [offset, setOffset] = useState(0);
  const classes = useStyles();

  // react-to-print use for printing the modal
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });

  const { loading, error, data } = useQuery(CAUSAL_CHAINS_SUMMARY, {
    fetchPolicy: 'network-only',
    variables: {
      timeSlice,
      offset
    }
  });

  const handleOffsetChange = change => {
    setOffset(prevOffset => (prevOffset + change < 0 ? 0 : prevOffset + change));
  };

  if (loading) {
    return <AlbLoading />;
  }

  if (error) {
    return <AlbError error={error} toast={false} />;
  }

  if (!loading && !data?.causalChainsSummary[0]?.summary) {
    return (
      <>
        <DateView
          timeSlice={timeSlice}
          data={data}
          offset={offset}
          handleOffsetChange={handleOffsetChange}
        />
        <h1 className={`${classes.title} printTitle`}>
          {data?.causalChainsSummary[0]?.time_period?.period_type || ''} Intelligence Briefing
        </h1>
        <StayTuned />;
      </>
    );
  }

  return (
    <div ref={printRef}>
      <style>{setPrintStyling()}</style>
      <Grid
        className="printLogo"
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <img src={LogoColored} alt="Alembic Logo" height="40px" />
      </Grid>

      <Grid container className={classes.topMatter}>
        <h1 className={`${classes.title} printTitle`}>
          {data?.causalChainsSummary[0]?.time_period?.period_type || ''} Intelligence Briefing
        </h1>
        <Grid className={`${classes.rightSide} printRightSide`}>
          <DateView
            timeSlice={timeSlice}
            data={data}
            offset={offset}
            handleOffsetChange={handleOffsetChange}
          />
          <IconButton className={`${classes.printButton} printBtn`} onClick={reactToPrintFn}>
            <Print />
          </IconButton>
        </Grid>
      </Grid>
      <SummaryOfSummaries summary={data?.causalChainsSummary[0]?.summary} />
    </div>
  );
};

InsightsTabPanel.propTypes = {
  timeSlice: PropTypes.string.isRequired
};

export default InsightsTabPanel;
