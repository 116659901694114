/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Tooltip, Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import { ArrowDropDown } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';

import LinkIcon from '@material-ui/icons/Link';

import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';
import { goToRoute } from 'util/routesHelpers';
import { LinkAdobeAnalyticsSegmentPath, LinkGoogleAnalyticsFilterPath } from 'util/paths';
import { showToast } from 'contexts/ToastContext';

import { getConfigObject, getConsentURL } from 'components/OAuth/constants';
import EnableMenuItem from './EnableMenuItem';
import DisableMenuItem from './DisableMenuItem';
import UnlinkSocial from './UnlinkSocial';

const onLogin = async (event, type) => {
  event.preventDefault();
  let consentURL;

  if (type === LINKTOKEN_TYPE_ENUM.TIKTOK) {
    await fetch(`${process.env.API_URL}/tiktok_oauth`)
      .then(response => response.json())
      .then(response => {
        consentURL = response.url;
      })
      .catch(() => {
        showToast('Could not get TikTok consent URL', 'error');
      });
  } else {
    consentURL = await getConsentURL(getConfigObject(type));
  }

  if (consentURL) {
    window.location = consentURL;
  } else {
    showToast(
      'This account requires relinking of the main account to repair. Please relink the main account.',
      'error'
    );
  }
};

const AccountMenu = props => {
  const {
    // the button that opens the menu
    buttonColor,
    buttonStyle,
    buttonText,
    id,
    remote_name,
    type,
    isEnabled,
    canRepair,
    canBeToggled,
    canBeUnlinked,
    refetch,
    error,
    valid_token,
    history
  } = props;

  // This menu contain an incredible amount of state inside of it!
  //
  // If you're the owner:
  //   - On primary accounts: you can unlink it and all sub accounts.
  //   - On sub accounts: you can enable/disable it.
  //   - You are the only person that can repair the account.
  //
  // If you're part of the org with LINKS:DELETE
  //   - You can release the account
  //   - You can unlink it and all sub accounts.

  // TODO: "Privacy" - hide accounts from other members in org
  // TODO: (Filters) Show only enabled accounts / show only your accounts / show all in org

  const ref = React.createRef();
  let errorMessage = '';

  // If there is an error, we need to show it to the user. Tooltip gets very
  // unhappy about nulls, so we need to make sure we have a string.
  if (!valid_token) {
    if (error?.message) {
      errorMessage = error.message;
    } else {
      errorMessage = 'Unknown error';
    }
  }

  return (
    <div>
      <PopupState variant="popover" popupId={`popup-${id}`}>
        {popupState => (
          <React.Fragment>
            <Tooltip title={errorMessage}>
              <Button
                variant="contained"
                {...bindTrigger(popupState)}
                startIcon={<ArrowDropDown />}
                color={buttonColor}
                style={buttonStyle}
                fullWidth
              >
                {buttonText}
              </Button>
            </Tooltip>
            <Menu {...bindMenu(popupState)} ref={ref}>
              {canBeToggled && !isEnabled && (
                <EnableMenuItem
                  ref={ref}
                  id={id}
                  accountType={type}
                  remoteName={remote_name}
                  refetch={refetch}
                  close={popupState.close}
                />
              )}
              {canBeToggled && isEnabled && (
                <DisableMenuItem
                  ref={ref}
                  id={id}
                  remoteName={remote_name}
                  refetch={refetch}
                  close={popupState.close}
                />
              )}
              {canBeUnlinked && (
                <UnlinkSocial
                  ref={ref}
                  id={id}
                  accountType={type}
                  remoteName={remote_name}
                  refetch={refetch}
                  close={popupState.close}
                />
              )}

              {canRepair && (
                <MenuItem innerRef={ref} onClick={event => onLogin(event, type)}>
                  <ListItemIcon>
                    <RefreshIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Re-Link</ListItemText>
                </MenuItem>
              )}

              {type === LINKTOKEN_TYPE_ENUM.AA_REPORT_SUITE && (
                <MenuItem
                  innerRef={ref}
                  onClick={() => {
                    goToRoute(`${LinkAdobeAnalyticsSegmentPath}?account=${id}`, history);
                  }}
                >
                  <ListItemIcon>
                    <LinkIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Link Segments</ListItemText>
                </MenuItem>
              )}

              {type === LINKTOKEN_TYPE_ENUM.GOOGLE_WEBPROPERTY_G4 && (
                <MenuItem
                  innerRef={ref}
                  onClick={() => {
                    goToRoute(
                      `${LinkGoogleAnalyticsFilterPath}?account=${id}&action=link`,
                      history
                    );
                  }}
                >
                  <ListItemIcon>
                    <LinkIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Link Filter Expression</ListItemText>
                </MenuItem>
              )}

              {type === LINKTOKEN_TYPE_ENUM.GOOGLE_WEBPROPERTY_G4_FILTER && (
                <MenuItem
                  innerRef={ref}
                  onClick={() => {
                    goToRoute(
                      `${LinkGoogleAnalyticsFilterPath}?account=${id}&action=update`,
                      history
                    );
                  }}
                >
                  <ListItemIcon>
                    <LinkIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Filter Expression</ListItemText>
                </MenuItem>
              )}

              {!canBeToggled &&
                !canRepair &&
                !canBeUnlinked &&
                type !== LINKTOKEN_TYPE_ENUM.GOOGLE_WEBPROPERTY_G4_FILTER && (
                  <MenuItem onClick={popupState.close}>No Actions</MenuItem>
                )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </div>
  );
};

AccountMenu.propTypes = {
  buttonColor: PropTypes.string,
  buttonStyle: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string
  }),
  buttonText: PropTypes.string,
  id: PropTypes.string.isRequired,
  remote_name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  canRepair: PropTypes.bool.isRequired,
  canBeToggled: PropTypes.bool.isRequired,
  canBeUnlinked: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  valid_token: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired
};

AccountMenu.defaultProps = {
  buttonColor: 'primary',
  buttonStyle: {},
  buttonText: 'Linked',
  error: {}
};

export default AccountMenu;
