/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_MENTION_GROUP_FOR_DRAWER } from 'gql/mention_group';
import { makeStyles } from '@material-ui/core/styles';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import ThirdPartyMediaDrawerMentions from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaDrawer/ThirdPartyMediaDrawerMentions';
import { NARRATIVE_EVENT_TYPES } from 'util/detectedEvents';
import DetectedEventDrawerGraph from './DetectedEventDrawerGraph';
import DetectedEventDrawerTable from './DetectedEventDrawerTable';

const useStyles = makeStyles({
  dateContainer: {
    padding: '16px 27px 30px 27px'
  },
  dateLabel: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#0A1734'
  },
  dateText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#585858'
  },
  graphContainer: {
    padding: '0px 20px'
  },
  container: {
    paddingTop: '30px'
  },
  title: {
    color: '#0a1734',
    fontSize: '16px',
    fontWeight: 500,
    paddingLeft: '27px'
  }
});

const DetectedEventDrawerView = props => {
  const { detectedEvent, graphDates } = props;
  const classes = useStyles();

  const [
    getMentionGroupForDrawer,
    { loading, error, data }
  ] = useLazyQuery(GET_MENTION_GROUP_FOR_DRAWER, { fetchPolicy: 'network-only' });

  useEffect(() => {
    // conditional query for narrative events
    if (NARRATIVE_EVENT_TYPES.includes(detectedEvent?.event_type)) {
      const mentionGroupId = detectedEvent?.secondary_id;

      // pull mentions list
      getMentionGroupForDrawer({ variables: { id: mentionGroupId } });
    }
  }, [detectedEvent]);

  return (
    <>
      <Grid className={classes.dateContainer}>
        <Grid container direction="column">
          <Grid item>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateLabel}>
              Detection Start:{' '}
            </Typography>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateText}>
              {moment.utc(detectedEvent?.event_start_date).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateLabel}>
              Detection End:{' '}
            </Typography>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateText}>
              {moment.utc(detectedEvent?.event_end_date).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.graphContainer}>
        <DetectedEventDrawerGraph detectedEvent={detectedEvent} graphDates={graphDates} />
      </Grid>
      {NARRATIVE_EVENT_TYPES.includes(detectedEvent?.event_type) && (
        <Grid container className={classes.container} direction="column">
          {loading && <AlbLoading />}
          {error && <AlbError error={error} />}
          {!loading && !error && (
            <>
              <Typography className={classes.title}>Mentions Breakdown</Typography>
              <ThirdPartyMediaDrawerMentions
                mentionGroupData={data}
                eventType={detectedEvent.event_type}
              />
            </>
          )}
        </Grid>
      )}
      <DetectedEventDrawerTable detectedEvent={detectedEvent} />
    </>
  );
};

DetectedEventDrawerView.propTypes = {
  detectedEvent: PropTypes.shape().isRequired,
  graphDates: PropTypes.shape()
};

DetectedEventDrawerView.defaultProps = {
  graphDates: null
};

export default DetectedEventDrawerView;
