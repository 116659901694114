import { gql } from '@apollo/client';

export const SAML_FIELDS = gql`
  fragment samlFields on SamlIDP {
    id
    organization_id
    email_domain
    entity_id
    sso_url
    slo_url
    x509_certificate
    sign_authn_request
    force_authn
    authn_context_class_ref
    our_issuer_id
    idp_signs_authn_response
    idp_signs_logout_response
    idp_signs_assertions
    update_profile_on_login
    allow_email_address_change
    allow_name_change
    signin_button_label
    new_user_role_id
    created_at
    updated_at
  }
`;

export const GET_SAML_BY_DOMAIN = gql`
  query getSAMLIDPByDomain($email_domain: String!) {
    getSAMLIDPByDomain(email_domain: $email_domain) {
      id
      sso_url
      signin_button_label
    }
  }
`;

export const GET_CURRENT_SAML_CONFIG = gql`
  query getCurrentSAMLConfig {
    getCurrentSAMLConfig {
      ...samlFields
    }
  }
  ${SAML_FIELDS}
`;

export const GET_SAML_SSO_URL = gql`
  query getSAMLRedirectURL($id: ID!) {
    getSAMLRedirectURL(id: $id) {
      url
    }
  }
`;

export const CREATE_ORGANIZATION_SAML_SETTINGS = gql`
  mutation createSamlIDP($input: SamlIDPInput!) {
    createSamlIDP(input: $input) {
      ...samlFields
    }
  }
  ${SAML_FIELDS}
`;

export const UPDATE_ORGANIZATION_SAML_SETTINGS = gql`
  mutation updateSamlIDP($id: ID!, $input: SamlIDPInput!) {
    updateSamlIDP(id: $id, input: $input) {
      ...samlFields
    }
  }
  ${SAML_FIELDS}
`;

export const DELETE_ORGANIZATION_SAML_SETTINGS = gql`
  mutation deleteSamlIDP($id: ID!) {
    deleteSamlIDP(id: $id)
  }
`;
