import React, { useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { TableCell, CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import ToastContext, { showToast } from 'contexts/ToastContext';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'util/routes';
import { RenderRoutes } from 'util/routesHelpers';
import ReactGA from 'react-ga4';

import theme from './theme';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datetime/css/react-datetime.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@brainhubeu/react-carousel/lib/style.css';
import AlbLoading from './components/AlbLoading';
import PostDrawerView from './components/PostDrawerView';
import ArticleDrawerView from './components/ArticleDrawerView';
import DashboardIntelligenceDrawer from './components/DashboardIntelligenceDrawer';
import DashboardUnderstandDrawer from './components/DashboardUnderstandDrawer';
import store from './store/index';
import { restoreAuthentication, deauthenticate, checkExistingAuth } from './actions/authActions';

import PostDrawerWrapper from './contexts/PostDrawerWrapper';
import ArticleDrawerWrapper from './contexts/ArticleDrawerWrapper';
import DashboardDrawerWrapper from './contexts/DashboardDrawerWrapper';
import DashboardUnderstandDrawerWrapper from './contexts/DashboardUnderstandDrawerWrapper';

// this is a monkey patch to fix bad a bad rendering bug inside of material-ui
// Remove when we go to Material 5 or later -- jna 3/28/2023
const oldRender = TableCell.render;

TableCell.render = (...args) => {
  const [props, ...otherArgs] = args;
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props;
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
  }
  return oldRender.apply(this, args);
};

const boxTheme = {
  spacing: 1
};

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  // Potential race condition here, if we run on page ready, we might not have
  // redux ready yet.
  const initializeState = async () => {
    const userId = localStorage.getItem('alb_user_id');
    const expires = localStorage.getItem('alb_login_expires');
    if (!userId) {
      // eslint-disable-next-line no-console
      console.log('initializeState: no user id in localStorage');
      store.dispatch(deauthenticate());
      return;
    }

    if (!expires) {
      // eslint-disable-next-line no-console
      console.log('initializeState: no expires in localStorage');
      store.dispatch(deauthenticate());
      return;
    }

    if (Date.now() < Date.parse(expires)) {
      // This just flips the 'isLoading' bit which will cause
      // the redux-auth middleware to not redirect until we
      // clear the bit via deauth or auth success
      store.dispatch(checkExistingAuth());

      try {
        // authenticate user runs a graphQL call to verify the user
        // if successful. it will then dispatch authenticate.
        restoreAuthentication();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error authenticating user', error);
        // deauthenticate clears the session from the UI
        store.dispatch(deauthenticate());
      }
    }
  };

  // On page load, setup GA4, and see if we've got user credentials in
  // localStorage. If so, authenticate the user.
  useEffect(() => {
    initializeState();
    ReactGA.initialize(process.env.GA4_ALEMBIC_MEAS_ID);
  }, []);

  return (
    <ToastContext.Provider
      value={{
        showToast
      }}
    >
      <PostDrawerWrapper>
        <ArticleDrawerWrapper>
          <DashboardDrawerWrapper>
            <DashboardUnderstandDrawerWrapper>
              <ToastContainer />
              <PostDrawerView />
              <ArticleDrawerView />
              <DashboardIntelligenceDrawer />
              <DashboardUnderstandDrawer />
              <CssBaseline />
              <ThemeProvider theme={boxTheme}>
                <MuiThemeProvider theme={theme}>
                  <Suspense fallback={<AlbLoading />}>
                    <RenderRoutes routes={[...PUBLIC_ROUTES, ...PRIVATE_ROUTES]} />
                  </Suspense>
                </MuiThemeProvider>
              </ThemeProvider>
            </DashboardUnderstandDrawerWrapper>
          </DashboardDrawerWrapper>
        </ArticleDrawerWrapper>
      </PostDrawerWrapper>
    </ToastContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    currentContainer: state.auth.currentContainer,
    isAuthenticated: state.auth.isAuthenticated
  };
};

export default connect(mapStateToProps)(App);
