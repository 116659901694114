import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Tooltip,
  Button
} from '@material-ui/core';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import { SECURITY_ROLES } from 'gql/securityRole';
import {
  GET_CURRENT_SAML_CONFIG,
  CREATE_ORGANIZATION_SAML_SETTINGS,
  UPDATE_ORGANIZATION_SAML_SETTINGS,
  DELETE_ORGANIZATION_SAML_SETTINGS
} from 'gql/saml';
import { showToast } from 'contexts/ToastContext';
import AlembicModalConfirm from '../AlembicModalConfirm';

// Set the initial state for the form on create (and reset on delete)
const initialState = {
  recordId: null,
  domainName: '',
  role: '',
  idpEndpoint: '',
  idpIssuer: '',
  idpCert: '',
  signAuthN: true,
  signResponse: true,
  signAssertion: true,
  spIssuer: 'https://api.getalembic.com/saml/sp',
  authNContext: 'urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport',
  updateOnLogin: true,
  allowEmailChange: false,
  allowDisplayNameChange: false,
  forceAuth: false,
  signInButtonLabel: 'Sign in with SAML',
  // This role will be looked up on load in the role list as the
  // ID number can vary from dev/stage/testing/production
  // It must be a role of type "S" (for system-based role)
  alembicUserRole: 'Link Only'
};
const placeholderCert = `-----BEGIN CERTIFICATE-----\n....\n-----END CERTIFICATE-----`;

const SAMLSettings = () => {
  // if non-null we are updating not creating
  const [recordId, setRecordId] = useState(null);

  const [domainName, setDomainName] = useState(initialState.domainName);
  const [role, setRole] = useState(initialState.role);
  const [idpEndpoint, setIdpEndpoint] = useState(initialState.idpEndpoint);
  const [idpIssuer, setIdpIssuer] = useState(initialState.idpIssuer);
  const [idpCert, setIdpCert] = useState(initialState.idpCert);
  const [signAuthN, setSignAuthN] = useState(initialState.signAuthN);
  const [signResponse, setSignResponse] = useState(initialState.signResponse);
  const [signAssertion, setSignAssertion] = useState(initialState.signAssertion);
  const [spIssuer, setSPIssuer] = useState(initialState.spIssuer);
  const [authNContext, setAuthNContext] = useState(initialState.authNContext);
  const [updateOnLogin, setUpdateOnLogin] = useState(initialState.updateOnLogin);
  const [allowEmailChange, setAllowEmailChange] = useState(initialState.allowEmailChange);
  const [allowDisplayNameChange, setAllowDisplayNameChange] = useState(
    initialState.allowDisplayNameChange
  );
  const [forceAuth, setForceAuth] = useState(initialState.forceAuth);
  const [signInButtonLabel, setSignInButtonLabel] = useState(initialState.signInButtonLabel);
  const [defaultUserRole, setDefaultUserRole] = useState(null);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  // Load the security roles
  const { loading: rolesLoading, error: rolesError, data: rolesData } = useQuery(SECURITY_ROLES);

  // Load the current SAML config
  const { loading: configLoading, error: configError, data: configData } = useQuery(
    GET_CURRENT_SAML_CONFIG
  );

  const [updateSAMLSettings] = useMutation(UPDATE_ORGANIZATION_SAML_SETTINGS);
  const [createSAMLSettings] = useMutation(CREATE_ORGANIZATION_SAML_SETTINGS);
  const [deleteSAMLSettings] = useMutation(DELETE_ORGANIZATION_SAML_SETTINGS);

  useEffect(() => {
    // We'd like to default to the system "Link Only" role. Find it
    if (!rolesData?.security_roles) return;

    for (let i = 0; i < rolesData.security_roles.length; i += 1) {
      if (
        rolesData.security_roles[i].name === initialState.alembicUserRole &&
        rolesData.security_roles[i].role_type === 'S'
      ) {
        setDefaultUserRole(parseInt(rolesData.security_roles[i].id, 10));
        // set the default if we don't have a role
        if (!role) setRole(parseInt(rolesData.security_roles[i].id, 10));
        initialState.role = defaultUserRole;
        break;
      }
    }
  }, [rolesData]);

  useEffect(() => {
    // If we have data, set the state.
    if (configData?.getCurrentSAMLConfig) {
      const {
        id: newRecordId,
        email_domain: newDomainName,
        entity_id: newIdpIssuer,
        sso_url: newIdpEndpoint,
        x509_certificate: newIdpCert,
        sign_authn_request: newSignAuthN,
        force_authn: newForceAuth,
        authn_context_class_ref: newAuthNContext,
        our_issuer_id: newSPIssuer,
        idp_signs_authn_response: newSignResponse,
        idp_signs_assertions: newSignAssertion,
        update_profile_on_login: newUpdateOnLogin,
        allow_email_address_change: newAllowEmailChange,
        allow_name_change: newAllowDisplayNameChange,
        signin_button_label: newSignInButtonLabel,
        new_user_role_id: newRole
      } = configData.getCurrentSAMLConfig;

      setRole(newRole);
      setRecordId(newRecordId);
      setDomainName(newDomainName);
      setIdpIssuer(newIdpIssuer);
      setIdpEndpoint(newIdpEndpoint);
      setIdpCert(newIdpCert);
      setSignAuthN(newSignAuthN);
      setForceAuth(newForceAuth);
      setAuthNContext(newAuthNContext);
      setSPIssuer(newSPIssuer);
      setSignResponse(newSignResponse);
      setSignAssertion(newSignAssertion);
      setUpdateOnLogin(newUpdateOnLogin);
      setAllowEmailChange(newAllowEmailChange);
      setAllowDisplayNameChange(newAllowDisplayNameChange);
      setSignInButtonLabel(newSignInButtonLabel);
    }
  }, [configData]);

  /**
   * Handle saving of the record to graphQL
   */
  const handleUpdate = () => {
    updateSAMLSettings({
      variables: {
        id: recordId,
        input: {
          email_domain: domainName,
          entity_id: idpIssuer,
          sso_url: idpEndpoint,
          // slo_url: String FUTURE
          x509_certificate: idpCert,
          sign_authn_request: signAuthN,
          force_authn: forceAuth,
          authn_context_class_ref: authNContext,
          our_issuer_id: spIssuer,
          idp_signs_authn_response: signResponse,
          // idp_signs_logout_response:  FUTURE
          idp_signs_assertions: signAssertion,
          update_profile_on_login: updateOnLogin,
          allow_email_address_change: allowEmailChange,
          allow_name_change: allowDisplayNameChange,
          signin_button_label: signInButtonLabel,
          new_user_role_id: role
        }
      }
    })
      .then(response => {
        if (response?.data?.updateSamlIDP)
          showToast('Successfully updated SAML configuration', 'success');
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  /**
   * Handle saving of the record to graphQL
   */
  const handleDelete = () => {
    deleteSAMLSettings({
      variables: {
        id: recordId
      }
    })
      .then(response => {
        if (response?.data?.deleteSamlIDP) {
          showToast('Successfully deleted SAML configuration', 'success');
          // Close the modal
          setConfirmDeleteModalOpen(false);

          // Reset the form
          setRecordId(null);
          setDomainName(initialState.domainName);
          setRole(initialState.role);
          setIdpIssuer(initialState.idpIssuer);
          setIdpEndpoint(initialState.idpEndpoint);
          setIdpCert(initialState.idpCert);
          setSignAuthN(initialState.signAuthN);
          setSignResponse(initialState.signResponse);
          setSignAssertion(initialState.signAssertion);
          setSPIssuer(initialState.spIssuer);
          setAuthNContext(initialState.authNContext);
          setUpdateOnLogin(initialState.updateOnLogin);
          setAllowEmailChange(initialState.allowEmailChange);
          setAllowDisplayNameChange(initialState.allowDisplayNameChange);
          setForceAuth(initialState.forceAuth);
          setSignInButtonLabel(initialState.signInButtonLabel);
        }
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  /**
   * Handle saving of the record to graphQL
   */
  const handleCreate = () => {
    createSAMLSettings({
      variables: {
        input: {
          email_domain: domainName,
          entity_id: idpIssuer,
          sso_url: idpEndpoint,
          // slo_url: String FUTURE
          x509_certificate: idpCert,
          sign_authn_request: signAuthN,
          force_authn: forceAuth,
          authn_context_class_ref: authNContext,
          our_issuer_id: spIssuer,
          idp_signs_authn_response: signResponse,
          // idp_signs_logout_response:  FUTURE
          idp_signs_assertions: signAssertion,
          update_profile_on_login: updateOnLogin,
          allow_email_address_change: allowEmailChange,
          allow_name_change: allowDisplayNameChange,
          signin_button_label: signInButtonLabel,
          new_user_role_id: role
        }
      }
    })
      .then(response => {
        if (response?.data?.createSamlIDP) {
          showToast('Successfully created SAML configuration', 'success');
          setRecordId(response.data.createSamlIDP.id);
        }
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  /**
   * Show an error message in a toast.
   */
  const handleError = () => {
    showToast('Please correct errors in the form before saving.', 'error');
  };
  /**
   * Handle create or update of the config.
   * If we have a recordId, we're updating, otherwise we're creating.
   * We'll check for errors and show a toast if we have any.
   */
  const handleSubmit = () => {
    // is one of the signing options checked?
    //
    // I wanted to do this under a custom validator, but validating against
    // multiple checkboxes requires the underlying component to be able to
    // access the state of the other checkboxes, which it can't do. So we'll
    // handle this at submit time instead.
    if (!signResponse && !signAssertion) {
      showToast('You must select at least one request signing option.', 'error');
      return;
    }

    // either create or update the record based on ID
    if (recordId) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  // We won't proceed until we're done loading.
  if (rolesLoading || configLoading) return <AlbLoading />;
  if (rolesError) return <p>{rolesError.message}</p>;

  if (configError) return <p>{configError.message}</p>;

  return (
    <>
      <Box p={30}>
        <ValidatorForm onSubmit={handleSubmit} onError={handleError}>
          <Grid container direction="row" spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={12} lg={12} alignItems="flex-start">
              <Typography variant="h3" paragraph>
                Configure Custom SAML Authentication
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography paragraph>
                Alembic supports a number of single sign-on (SSO) Services through{' '}
                <a href="https://en.wikipedia.org/wiki/SAML_2.0">SAML 2.0</a>.
              </Typography>
              <Typography paragraph>
                To get started, you will need to configure your Identity Provider (IdP) and tell us
                how your IdP is configured below. If you need help, please contact support.
              </Typography>
              <Typography paragraph>
                For our Service Provider&#39;s metadata (containing our public key), please
                reference{' '}
                <a href={`${process.env.API_URL}/saml/sp/metadata`}>
                  {process.env.API_URL}/saml/sp/metadata
                </a>
                .
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>Domain Name</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                The domain name for your organization. Must match your Alembic e-mail, and the
                domain of your users.
              </InputLabel>
              <TextValidator
                fullWidth
                name="domainName"
                variant="filled"
                placeholder="example.com"
                value={domainName}
                validators={['required']}
                errorMessages={['* Required']}
                onChange={event => setDomainName(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>SAML 2.0 IdP Endpoint (HTTPS)</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                Enter the URL of your SAML 2.0 IdP endpoint.
                <br />
                This is the URL that Alembic will use to authenticate your users.
              </InputLabel>
              <TextValidator
                fullWidth
                name="idpEndpoint"
                variant="filled"
                placeholder="https://example.com/saml/login"
                value={idpEndpoint}
                validators={['required']}
                errorMessages={['* Required']}
                onChange={event => setIdpEndpoint(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>Identity Provider Issuer (Entity ID)</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                The Entity ID of the SAML assertion.
                <br />
                This is the entity ID of your service, which asserts the identity of the user.
              </InputLabel>
              <TextValidator
                fullWidth
                name="idpIssuer"
                variant="filled"
                placeholder="https://example.com/saml/sp"
                value={idpIssuer}
                validators={['required']}
                errorMessages={['* Required']}
                onChange={event => setIdpIssuer(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>Identity Provider X.509 Certificate</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                Copy and paste your IdP&#39;s X.509 certificate here
                <br />
                This is used to verify the authenticity of the SAML assertion and should be in PEM
                format.
              </InputLabel>
              <TextValidator
                fullWidth
                name="idpIssuer"
                variant="filled"
                placeholder={placeholderCert}
                value={idpCert}
                validators={['required']}
                errorMessages={['* Required']}
                multiline
                rows={6}
                onChange={event => setIdpCert(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <strong>User Role</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                What role should be granted on account to newly created accounts?
              </InputLabel>
              <SelectValidator
                key="sv-1"
                fullWidth
                name="role"
                variant="filled"
                placeholder="Link Only"
                defaultValue={defaultUserRole}
                value={role}
                onChange={event => setRole(event.target.value)}
                validators={['required']}
                errorMessages={['* Required']}
              >
                {rolesData.security_roles.map(securityRole => {
                  return (
                    <MenuItem key={securityRole.id} value={parseInt(securityRole.id, 10)}>
                      <Tooltip title={securityRole.description}>
                        <div>{securityRole.name}</div>
                      </Tooltip>
                    </MenuItem>
                  );
                })}
              </SelectValidator>
              <Typography variant="caption" paragraph>
                We will automatically generate an Alembic account when your users authenticate for
                the first time. This is known as JIT (Just-in-Time) provisioning.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" paragraph>
                <strong>Settings</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updateOnLogin}
                    onChange={event => setUpdateOnLogin(event.target.checked)}
                    name="updateOnLogin"
                  />
                }
                label="Update profile each time a user logs in"
              />
              <Typography variant="caption" paragraph>
                Available profile fields will be synched from your IdP every time a user logs in.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowEmailChange}
                    onChange={event => setAllowEmailChange(event.target.checked)}
                    name="allowEmailChange"
                  />
                }
                label="Allow users to change their email address"
              />
              <Typography variant="caption" paragraph>
                If enabled, users will be able to change their email address in Alembic.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowDisplayNameChange}
                    onChange={event => setAllowDisplayNameChange(event.target.checked)}
                    name="allowDisplayNameChange"
                  />
                }
                label="Allow users to change their display name"
              />
              <Typography variant="caption" paragraph>
                Users will be able to change their display name to something other than the one
                provided by the IdP.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" paragraph>
                <strong>Advanced Configuration</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <strong>Sign AuthN Request</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                If Alembic will sign outgoing AuthN Requests to your IdP with our public key.
              </InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={signAuthN}
                    onChange={event => setSignAuthN(event.target.checked)}
                    name="signAuthN"
                  />
                }
                label="Sign AuthN Request"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>AuthnContextClassRef</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                The AuthnContextClassRef value to be sent in the AuthN request.
              </InputLabel>
              <TextValidator
                fullWidth
                name="authNContext"
                variant="filled"
                placeholder="urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport"
                value={authNContext}
                onChange={event => setAuthNContext(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>Service Provider (SP) Issuer</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                How you would like us to identify ourselves to the IdP.
              </InputLabel>
              <TextValidator
                fullWidth
                name="serviceProviderIssuer"
                variant="filled"
                placeholder="https://api.getalembic.com/sp"
                value={spIssuer}
                onChange={event => setSPIssuer(event.target.value)}
                validators={['required']}
                errorMessages={['* Required']}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" paragraph>
                Request Signing
              </Typography>
              <Typography paragraph>
                <strong>
                  Choose how the SAML Response from your IdP is signed. You must choose at least one
                  option.
                </strong>
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={signResponse}
                    onChange={event => setSignResponse(event.target.checked)}
                    name="signResponse"
                  />
                }
                label="Your IdP signs the AuthN Response"
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={signAssertion}
                    onChange={event => setSignAssertion(event.target.checked)}
                    name="signAssertion"
                  />
                }
                label="Your IdP signs assertions in the AuthN Response"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={forceAuth}
                    onChange={event => setForceAuth(event.target.checked)}
                    name="forceAuth"
                  />
                }
                label="Force IDP User Authentication"
              />
              <Typography variant="caption" paragraph>
                If enabled, users will be required to authenticate to use the Idp for each session.
                If disabled, the IdP will be offered but Alembic&#39;s password login will still be
                allowed.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>Sign In Button Label</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                The text that will appear on the Sign In button.
              </InputLabel>
              <TextValidator
                fullWidth
                name="signInButtonLabel"
                variant="filled"
                placeholder="Sign in with SAML"
                value={signInButtonLabel}
                onChange={event => setSignInButtonLabel(event.target.value)}
                validators={['required']}
                errorMessages={['* Required']}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography>
                <strong>Button Preview</strong>
              </Typography>
              <InputLabel color="primary" style={{ marginLeft: 0 }}>
                Preview of the Sign In button.
              </InputLabel>
              <Button fullWidth align="center" color="secondary" variant="outlined">
                {signInButtonLabel}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth type="submit" variant="contained" color="primary">
                {recordId ? 'Update Settings' : 'Create Settings'}
              </Button>{' '}
              {recordId && (
                <>
                  <Button
                    fullWidth
                    onClick={() => setConfirmDeleteModalOpen(true)}
                    style={{ marginTop: '10px', background: 'red', color: 'white' }}
                  >
                    Delete Settings
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </ValidatorForm>
        {confirmDeleteModalOpen && (
          <AlembicModalConfirm
            isOpen={confirmDeleteModalOpen}
            title="Remove SAML Configuration"
            body={
              <>
                Are you sure you want to remove the SAML configuration? <br />
                <br />
                This will disable SAML authentication for all users in your organization.
              </>
            }
            cancelTitle="Cancel"
            confirmTitle="Remove"
            handleCancel={() => setConfirmDeleteModalOpen(false)}
            handleConfirm={() => handleDelete()}
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};
/*
SAMLSettings.propTypes = {
  currentUser: PropTypes.shape().isRequired
};
*/
export default connect(mapStateToProps)(SAMLSettings);
