import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { DRUID_ACCOUNT_TABLE } from 'gql/analytics';
import theme from 'theme';
import AlbLoading from 'components/AlbLoading';
import { DefaultUserAvatar } from 'util/assets';
import { handleNullTableValue } from 'util/social';

import { getDatesForAnalytics } from 'util/date';
import colors from 'util/colors';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

const AlbTableDruid = props => {
  const { accounts, dates, table, activeTab } = props;

  const classes = useStyles();

  const tableTheme = createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableHeadRow: {
        root: {
          boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          whiteSpace: 'nowrap',

          '&:nth-child(-n+2)': {
            width: 1
          }
        },
        sortAction: {
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(even)': {
            backgroundColor: '#f5f5f5'
          },
          '&:hover': {
            backgroundColor: colors.athensGray
          }
        }
      }
    }
  });

  const defaultTabSort = ['Change', 'Period End'];

  const [sortOrder, updateSortOrder] = useState({
    name: defaultTabSort[activeTab],
    direction: 'desc'
  });
  const [tableData, setTableData] = useState([]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data, loading } = useQuery(DRUID_ACCOUNT_TABLE, {
    variables: {
      linkTokens: accounts.map(({ id }) => id),
      // startDate: dates.start,
      // endDate: dates.end,
      startDate,
      endDate,
      table
    },
    fetchPolicy: 'network-only'
  });

  const formatTable = dataSet =>
    dataSet.map(({ id, metrics }) => {
      const currentAccount = accounts.find(account => account.id === id) || {};
      // eslint-disable-next-line camelcase
      const { label, platform = {}, avatar_url } = currentAccount;

      return {
        account: platform?.icon,
        label,
        // eslint-disable-next-line camelcase
        avatar_url: avatar_url || DefaultUserAvatar,
        ...metrics.reduce((acc, { column, total }) => {
          acc[column] = total;
          return acc;
        }, {})
      };
    });

  useEffect(() => {
    if (data?.accountTable) setTableData(formatTable(data.accountTable));
  }, [data, accounts, dates]);

  useEffect(() => {
    updateSortOrder({
      name: defaultTabSort[activeTab],
      direction: 'desc'
    });
  }, [activeTab]);

  const customImageRender = src =>
    src ? (
      <div style={{ textAlign: 'center' }}>
        <img alt="logo" src={src} style={{ width: 38, height: 38 }} />
      </div>
    ) : (
      ''
    );

  const customLabelRender = label => (
    <span style={{ fontSize: '13px', fontWeight: 700, color: '#32327D' }}>{label}</span>
  );

  const customNumberRender = number => (
    <div style={{ textAlign: 'center' }}>{handleNullTableValue(number)}</div>
  );

  const customPercentRender = percent => {
    const isPercent = true;

    return <div style={{ textAlign: 'center' }}>{handleNullTableValue(percent, isPercent)}</div>;
  };

  const columns = [
    {
      name: 'account',
      label: 'ACCOUNT',
      options: { customBodyRender: customImageRender, sort: false }
    },
    {
      name: 'avatar_url',
      label: 'HANDLE',
      options: { customBodyRender: customImageRender, sort: false }
    },
    {
      name: 'label',
      label: ' ',
      options: { customBodyRender: customLabelRender, sort: false }
    },
    {
      name: 'Period Start',
      label: 'PERIOD START',
      options: {
        customBodyRender: customNumberRender,
        setCellHeaderProps: () => ({
          className: classes.centeredHeader
        })
      }
    },
    {
      name: 'Period End',
      label: 'PERIOD END',
      options: {
        customBodyRender: customNumberRender,
        setCellHeaderProps: () => ({
          className: classes.centeredHeader
        })
      }
    },
    {
      name: 'Change',
      label: 'CHANGE',
      options: {
        customBodyRender: customNumberRender,
        setCellHeaderProps: () => ({
          className: classes.centeredHeader
        })
      }
    },
    {
      name: '% Change',
      label: '% CHANGE',
      options: {
        customBodyRender: customPercentRender,
        setCellHeaderProps: () => ({
          className: classes.centeredHeader
        })
      }
    }
  ];

  const customSort = (sortData, colIndex, order) =>
    sortData
      .sort((a, b) => (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1))
      .reverse();

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard',
    draggableColumns: { enabled: true, transitionTime: 300 },
    customSort,
    sortOrder,
    onColumnSortChange: (name, direction) => updateSortOrder({ name, direction })
  };

  if (loading) return <AlbLoading />;

  return (
    <MuiThemeProvider theme={tableTheme}>
      <MUIDataTable data={tableData} columns={columns} options={options} />
    </MuiThemeProvider>
  );
};

AlbTableDruid.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  table: PropTypes.string.isRequired,
  activeTab: PropTypes.number.isRequired
};

export default AlbTableDruid;
