function CausalSplitter(causalChainSummary) {
  // Replace the phrase so it gets picked up as its own section
  const modifiedSummary = causalChainSummary.replace(
    /Also On The Day of Conversion/g,
    '## Also On The Day of Conversion'
  );

  // Remove all instances of triple backticks
  const cleanedSummary = modifiedSummary.replace(/```/g, '');

  // Split the string on any number of hash marks followed by one space
  const steps = cleanedSummary.split(/#+\s+/);

  // Remove any empty strings from the steps array
  const filteredSteps = steps.filter(step => step.trim() !== '');

  return filteredSteps;
}
export default CausalSplitter;
