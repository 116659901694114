/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CAUSAL_CHAINS = gql`
  query causalChains($sortField: String, $terminatingEvents: [String]) {
    causalChains(sortField: $sortField, terminatingEvents: $terminatingEvents) {
      id
      title
      summary
      justification
      feedback: prompt_template_feedback_from_user {
        feedback_type
      }
      revenue
      start_date
      end_date
      terminating_event
      raw_chain_data
    }
  }
`;

export const CAUSAL_CHAINS_TERMINATING_EVENTS = gql`
  query causalChainsTerminatingEvents {
    causalChainsTerminatingEvents
  }
`;
