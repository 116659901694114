const isValidJson = str => {
  let isValid = false;

  if (typeof str !== 'string' || (typeof str === 'string' && str.length === 0)) {
    return isValid;
  }

  try {
    JSON.parse(str);
    isValid = true;
  } catch (e) {
    // not valid json
  }

  return isValid;
};

export default isValidJson;
