import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  SEARCH_SEGMENTS,
  CREATE_ADOBE_ANALYTICS_SEGMENT_LINK,
  GET_CHILD_AA_SEGMENTS
} from 'gql/segment';
import AlbError from 'components/AlbError';
import { showToast } from 'contexts/ToastContext';
import { parseQueryString, goToRoute, setQueryStringForAnalyticsModal } from 'util/routesHelpers';
import { LinkAccountsPath } from 'util/paths';
import AdobeAnalyticsSegmentsTable from 'components/AlbTable/AdobeAnalyticsSegmentsTable';
import AdobeAnalyticsSegmentsSearchTable from 'components/AlbTable/AdobeAnalyticsSegmentsSearchTable';

/**
 * @method
 * @summary This component contains a suggestion search field allowing the user to choose which segments to import
 * @name LinkAdobeAnalyticsAccountSegments
 * @return {Object} - React JSX
 */
const LinkAdobeAnalyticsAccountSegments = props => {
  const { history, location } = props;
  const [inputValue, setInputValue] = useState('');
  const [createSegmentError, setCreateSegmentError] = useState(null);
  const [account, setAccount] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  // after component is mount, parse the query string and update local state
  useEffect(() => {
    if (location?.search) {
      setAccount(parseInt(parseQueryString('account', location), 10));
    }
  }, []);

  useEffect(() => {
    setQueryStringForAnalyticsModal('account', [account]);
  }, [account]);

  const [
    useSearchSegments,
    { data: searchData, error: searchError, loading: searchLoading }
  ] = useLazyQuery(SEARCH_SEGMENTS);

  const { loading: segmentsLoading, data: segments } = useQuery(GET_CHILD_AA_SEGMENTS, {
    variables: {
      linktoken_id: account
    },
    fetchPolicy: 'network-only'
  });

  const [createAASegmentLink] = useMutation(CREATE_ADOBE_ANALYTICS_SEGMENT_LINK, {
    onError: setCreateSegmentError,
    onCompleted: result => {
      showToast(
        // eslint-disable-next-line camelcase
        `${result?.createAdobeAnalyticsSegmentLink.token.remote_name} segment has been linked. Please enable linktoken segment to begin data collection.`,
        'success'
      );
      goToRoute(LinkAccountsPath, history);
    }
  });

  useEffect(() => {
    if (searchData) {
      setHasSearched(true);
      const results = searchData?.searchSegments.map(segment => ({
        id: segment.id,
        name: segment.name,
        segment_id: segment.segment_id,
        is_linked: segment.is_linked ? (
          <div>
            <Typography>Linked</Typography>
            <Button disabled variant="contained" color="primary">
              Link
            </Button>
          </div>
        ) : (
          <div>
            <Typography>Available</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={e => {
                e.stopPropagation();
                createAASegmentLink({
                  variables: { linktoken_id: account, segment_id: segment.segment_id }
                });
              }}
            >
              Link
            </Button>
          </div>
        )
      }));
      setSearchResults(results);
    }
  }, [searchData]);

  const searchSegments = () => {
    useSearchSegments({
      variables: {
        linktoken_id: account,
        query: inputValue.trim()
      },
      fetchPolicy: 'no-cache'
    });
  };

  const handleSearchInputChange = e => {
    setInputValue(e.target.value);
  };

  const tableColumns = [
    { name: 'remote_id', label: 'ID' },
    { name: 'remote_name', label: 'NAME' }
  ];

  const searchTableColumns = [
    { name: 'segment_id', label: 'ID' },
    { name: 'name', label: 'NAME' },
    { name: 'is_linked', label: 'LINKED' }
  ];

  return (
    <>
      {searchError && <AlbError toast error={searchError || createSegmentError} />}
      <ValidatorForm onSubmit={searchSegments}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Select Adobe Analytics Segments</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="medium"
              variant="outlined"
              placeholder="Search Segments to Link"
              onChange={handleSearchInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={searchLoading}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>

      {segments?.getChildAASegments.length > 0 && !hasSearched ? (
        <AdobeAnalyticsSegmentsTable
          isLoading={segmentsLoading}
          tableData={segments?.getChildAASegments ?? []}
          tableColumns={tableColumns}
        />
      ) : null}

      {hasSearched ? (
        <AdobeAnalyticsSegmentsSearchTable
          isLoading={searchLoading}
          tableData={searchResults ?? []}
          tableColumns={searchTableColumns}
        />
      ) : null}
    </>
  );
};

LinkAdobeAnalyticsAccountSegments.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default withRouter(LinkAdobeAnalyticsAccountSegments);
