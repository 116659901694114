import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItemIcon, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import Box from 'components/Box';
import { GET_MENTION_GROUP } from 'gql/mention_group';
import colors from 'util/colors';
import ThirdPartyMediaDrawerMentions from './ThirdPartyMediaDrawerMentions';

const useStyles = makeStyles({
  tableContainer: {
    flex: 1
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInner: {
    position: 'relative',
    minHeight: '40px',

    '& > :last-child': {
      paddingBottom: '0px !important'
    }
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px'
  },
  title: {
    marginRight: '10px',
    fontSize: '16px',
    fontWeight: 500,
    color: colors.navy
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineClamp: 3,
    wordBreak: 'break-word',
    fontSize: '13px'
  }
});

const ThirdPartyMediaDrawerTable = props => {
  const { details } = props;

  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const { data: mentionGroupData, loading, error } = useQuery(GET_MENTION_GROUP, {
    variables: {
      id: details.id,
      isDisabled: false
    },
    fetchPolicy: 'network-only'
  });

  return (
    <Grid container style={{ padding: '25px' }}>
      <Grid
        item
        className={classes.collapse}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        <ListItemIcon classes={{ root: classes.listItem }}>
          {isCollapsed ? <Right /> : <Down />}
        </ListItemIcon>
        {!isCollapsed && <Box className={classes.expandedIndicator} />}
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Grid item className={classes.headerLeft}>
          <Typography className={classes.title}>Mentions Breakdown</Typography>
        </Grid>
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          <Grid container justify="center">
            {loading && <AlbLoading />}
            {error && <AlbError error={error} />}
            {!loading && !error && (
              <ThirdPartyMediaDrawerMentions
                mentionGroupData={mentionGroupData}
                eventType={details.type}
              />
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

ThirdPartyMediaDrawerTable.propTypes = {
  details: PropTypes.shape().isRequired
};

export default ThirdPartyMediaDrawerTable;
