import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Typography,
  FormControl,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem
} from '@material-ui/core';
import DatePicker from 'components/DatePicker';

import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';

import { showToast } from 'contexts/ToastContext';
import { ORGANIZATION_SETTINGS, UPDATE_ORGANIZATION_MFA_SETTINGS } from 'gql/organization';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';

/**
 * A radio button which has a controlable DatePicker attached to it
 *
 * @param {Date} props.date the current date
 * @param {Function} props.setDate callback function on date change
 * @param {String} props.timeZone the current date
 * @param {Boolean} props.disabled if the date picker is disabled or not
 * @param {Integer} props.value what value the radio button represents
 *
 * @returns React-JSX
 */
const DateRadio = props => {
  const { date, setDate, timeZone, disabled, value } = props;

  return (
    <>
      <Radio value={value} />
      On From&nbsp;&nbsp;
      <DatePicker
        variant="outlined"
        value={date ? date.format('L LT') : null}
        handleDateChange={event => setDate(event)}
        timeZone={timeZone}
        disabled={disabled}
        isValidDate={selectedDate => selectedDate >= moment().startOf('day')}
      />
    </>
  );
};

DateRadio.propTypes = {
  date: PropTypes.shape(),
  setDate: PropTypes.instanceOf(Function).isRequired,
  timeZone: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.number
};

DateRadio.defaultProps = {
  date: null,
  value: 0
};

const MFASettings = props => {
  const { currentUser } = props;

  const [initialData, setInitialData] = useState({});
  const [mfaMandatory, setmfaMandatory] = useState(0);
  const [mfaOnFrom, setmfaOnFrom] = useState(); // undef by default
  const [mfaGrace, setmfaGrace] = useState(0);
  const [mfaPermitTrust, setmfaPermitTrust] = useState(true);

  const { data, loading, error: queryError } = useQuery(ORGANIZATION_SETTINGS, {
    fetchPolicy: 'network-only'
  });

  const [updateMFASettings] = useMutation(UPDATE_ORGANIZATION_MFA_SETTINGS);

  const gracePeriods = [0, 1, 7, 14, 30, 90, 180];

  useEffect(() => {
    if (data?.getCurrentOrganization) {
      const api = data.getCurrentOrganization;
      setInitialData(api);

      // unpack the options a bit so we can set the radio buttons correctly.
      if (!api.mfa_mandatory) {
        setmfaMandatory(0);
      }
      if (api.mfa_mandatory) {
        setmfaMandatory(1);
      }

      if (api.mfa_mandatory && api.mfa_on_from) {
        setmfaMandatory(2);
      }

      setmfaOnFrom(api.mfa_on_from);
      setmfaGrace(api.mfa_new_user_grace_days);
      setmfaPermitTrust(api.mfa_permit_device_trust);
    }
  }, [data]);

  const handleSetMandatory = event => {
    const newValue = parseInt(event.target.value, 10);
    // if you set it off option 2, we clear the date
    setmfaMandatory(newValue);

    if (newValue !== 2) {
      setmfaOnFrom(null);
    } else {
      setmfaOnFrom(moment());
    }
  };

  const handleSetGrace = event => {
    const newValue = parseInt(event.target.value, 10);
    // if you set it off option 2, we clear the date
    setmfaGrace(newValue);
  };

  const handleSave = () => {
    // Sanity check -- Don't let the user save if this change would lock them out.

    if (currentUser.otp_enabled !== true && mfaMandatory > 0) {
      showToast(
        'You do not have MFA enabled on your account. If you enable MFA now, you would lock yourself out. Please enroll in MFA first on the user profile page.',
        'error'
      );
      return;
    }

    updateMFASettings({
      variables: {
        mfa_mandatory: mfaMandatory > 0,
        mfa_on_from: mfaOnFrom,
        mfa_new_user_grace_days: mfaGrace,
        mfa_permit_device_trust: mfaPermitTrust
      }
    })
      .then(response => {
        if (response?.data?.updateMFASettings)
          showToast('Successfully saved MFA settings', 'success');
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  const handleCancel = () => {
    // unpack the options a bit so we can set the radio buttons correctly.

    if (initialData.mfa_mandatory) {
      setmfaMandatory(1);
    } else {
      setmfaMandatory(0);
    }

    if (initialData.mfa_mandatory && initialData.mfa_on_from) {
      setmfaMandatory(2);
    }
    setmfaOnFrom(initialData.mfa_on_from);
    setmfaGrace(initialData.mfa_new_user_grace_days);
    setmfaPermitTrust(initialData.mfa_permit_device_trust);
  };

  if (loading) return <AlbLoading />;
  if (queryError) return <p>{queryError.message}</p>;

  return (
    <Box p={30}>
      <ValidatorForm
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Grid container direction="column" spacing={4}>
          <Grid container item xs={10} md={10} lg={10}>
            <Typography paragraph>
              Enabling Multi-Factor Authentication adds an extra layer of security to your
              organization by asking users to enter a continually changing code along with their
              user name and password.
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems="center">
          <FormControl component="fieldset">
            <Typography variant="h3" paragraph>
              MFA Enforcement
            </Typography>

            <Typography paragraph>
              When this is enabled, users cannot log into your organization without MFA. You can set
              this to automatically enable after a set date using &#8220;on from&#8221; below. If
              your enterprise uses SAML/SSO. your enterprise handles login and Alembic does not
              enforce MFA.
            </Typography>
            <RadioGroup
              aria-label="MFA enforcement"
              name="enforcement"
              value={mfaMandatory}
              onChange={handleSetMandatory}
            >
              <FormControlLabel value={0} control={<Radio />} label="Off" />
              <FormControlLabel value={1} control={<Radio />} label="On" />
              <FormControlLabel
                value={2}
                control={
                  <DateRadio
                    date={mfaOnFrom}
                    setDate={setmfaOnFrom}
                    timeZone={currentUser.time_zone}
                    disabled={!(mfaMandatory === 2)}
                    value={2}
                  />
                }
              />
            </RadioGroup>

            <p>&nbsp;</p>

            <Typography variant="h3" paragraph>
              New User Enrollment Period
            </Typography>

            <Typography paragraph>
              Allows new and invited users in your organization some time to enroll in MFA before
              enforcement is applied.
            </Typography>

            <Grid container direction="column" spacing={4}>
              <Grid container item xs={6} md={6} lg={4}>
                <Typography gutterBottom>New User Grace Period</Typography>
                <SelectValidator
                  value={mfaGrace}
                  onChange={handleSetGrace}
                  variant="outlined"
                  fullWidth
                >
                  {gracePeriods.map(value => (
                    <MenuItem key={value} value={value}>
                      {value} days
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Grid>
            </Grid>

            <p>&nbsp;</p>

            <Typography variant="h3" paragraph>
              Frequency
            </Typography>

            <Typography paragraph>
              Checking this box will allow users to only enter an MFA code once every 30 days. If
              disabled, even if users check the &#8220;Remember this device&#8221; box at login, the
              selection will be ignored by Alembic.
            </Typography>

            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="mfa_permit_trust"
                      onClick={() => setmfaPermitTrust(!mfaPermitTrust)}
                      checked={mfaPermitTrust}
                    />
                  }
                  label="Allow Users to trust devices for 30 days"
                />
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item sm={2} md={1}>
                <Button variant="outlined" color="primary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={1}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </ValidatorForm>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

MFASettings.propTypes = {
  currentUser: PropTypes.shape().isRequired
};

export default connect(mapStateToProps)(MFASettings);
