import { gql } from '@apollo/client';

export const GET_MENTION_GROUP = gql`
  query getMentionGroup(
    $id: ID!
    $isDisabled: Boolean
    $startDate: DateTimeISO
    $endDate: DateTimeISO
  ) {
    getMentionGroup(id: $id) {
      id
      title
      group_seq
      mentionsNonAveraged(isDisabled: $isDisabled, startDate: $startDate, endDate: $endDate) {
        count
        rows {
          id
          air_time
          viewership
          source
          broadcast {
            id
            network_affiliation
            station_name
            station_guid
            program_title
          }
          podcast {
            id
            author
            podcast_title
          }
          broadcast_mention_text
          podcast_mention_text
        }
      }
    }
  }
`;

export const GET_MENTION_GROUPS = gql`
  query getMentionGroups(
    $ids: [ID!]
    $isDisabled: Boolean
    $startDate: DateTimeISO
    $endDate: DateTimeISO
  ) {
    getMentionGroups(ids: $ids) {
      id
      mentionsNonAveraged(isDisabled: $isDisabled, startDate: $startDate, endDate: $endDate) {
        count
        rows {
          id
          air_time
          viewership
          source
          broadcast {
            id
            network_affiliation
            station_name
            program_title
          }
          podcast {
            id
            author
            podcast_title
          }
          broadcast_mention_text
          podcast_mention_text
        }
      }
    }
  }
`;

export const GET_ENABLED_MENTION_GROUPS = gql`
  query getEnabledMentionGroups(
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $after: Int!
    $count: Int!
    $orderBy: [String]
    $mediaType: String
  ) {
    getEnabledMentionGroups(
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
      startDate: $startDate
      endDate: $endDate
      after: $after
      count: $count
      orderBy: $orderBy
      mediaType: $mediaType
    ) {
      count
      rows {
        id
        source
        firstAirDate
        firstAirDateSort
        lastAirDate
        length
        title
        group_seq
        highlight_mention_text
        mentionsCount
        viewershipCount
        disabled
        broadcast_search_id
        broadcastSearch {
          id
          name
        }
        podcast_search_id
        podcastSearch {
          id
          name
        }
        promotion_type
        mentions {
          count
        }
        totalViewership
        ai_summary
      }
    }
  }
`;

export const GET_DISABLED_MENTION_GROUPS = gql`
  query getDisabledMentionGroups(
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $after: Int!
    $count: Int!
    $orderBy: [String]
    $mediaType: String
  ) {
    getDisabledMentionGroups(
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
      startDate: $startDate
      endDate: $endDate
      after: $after
      count: $count
      orderBy: $orderBy
      mediaType: $mediaType
    ) {
      count
      rows {
        id
        source
        firstAirDate
        firstAirDateSort
        lastAirDate
        length
        title
        group_seq
        highlight_mention_text
        mentionsCount
        viewershipCount
        disabled
        broadcast_search_id
        broadcastSearch {
          id
          name
        }
        podcast_search_id
        podcastSearch {
          id
          name
        }
        promotion_type
        mentions {
          count
        }
        totalViewership
        ai_summary
      }
    }
  }
`;

export const GET_MENTION_GROUP_FOR_DRAWER = gql`
  query getMentionGroup(
    $id: ID!
    $isDisabled: Boolean
    $startDate: DateTimeISO
    $endDate: DateTimeISO
  ) {
    getMentionGroup(id: $id) {
      highlight_mention_text
      air_dates_druid {
        first_air_date
        last_air_date
      }
      mentionsNonAveraged(isDisabled: $isDisabled, startDate: $startDate, endDate: $endDate) {
        count
        rows {
          id
          air_time
          viewership
          source
          broadcast {
            id
            network_affiliation
            station_name
            station_guid
            program_title
          }
          podcast {
            id
            author
            podcast_title
          }
          broadcast_mention_text
          podcast_mention_text
        }
      }
    }
  }
`;

export const ENABLE_MENTION_GROUP = gql`
  mutation($id: ID!) {
    enableMentionGroup(id: $id) {
      id
      disabled
    }
  }
`;

export const DISABLE_MENTION_GROUP = gql`
  mutation($id: ID!) {
    disableMentionGroup(id: $id) {
      id
      disabled
    }
  }
`;

export const UPDATE_MENTION_GROUP_TITLE = gql`
  mutation($id: ID!, $title: String!) {
    updateMentionGroupTitle(id: $id, title: $title) {
      id
      title
    }
  }
`;

export const UPDATE_MENTION_GROUP_PROMOTION_TYPE = gql`
  mutation($id: ID!, $promotionType: MentionGroupPromotionTypeEnum) {
    updateMentionGroupPromotionType(id: $id, promotionType: $promotionType) {
      id
      promotion_type
    }
  }
`;
