/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, Select, MenuItem, Checkbox } from '@material-ui/core';
import {
  ThumbUp,
  ThumbDown,
  ThumbUpOutlined,
  ThumbDownOutlined,
  ExpandMore
} from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { CREATE_PROMPT_TEMPLATE_FEEDBACK } from 'gql/community';
import { showToast } from 'contexts/ToastContext';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import PromptFeedbackModal from 'components/AlembicModalForm/PromptFeedbackModal';
import CausalChainJustificationModal from 'components/AlembicModalConfirm/CausalChainJustificationModal';
import theme from 'theme';
import handleGraphQLError from 'util/error';
import { getDrawerHeaderTitle, getReadableDetectedEventTitle } from 'util/detectedEvents';
import CausalAccordion from './CausalAccordion';

const sortByTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        paddingTop: '11px',
        paddingLeft: '16px',

        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    }
  }
});

const terminatingTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        paddingTop: '11px',
        paddingLeft: '16px',

        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '0px'
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    }
  }
});

const useStyles = makeStyles({
  box: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '10px',
    padding: '36px',
    marginBottom: '48px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontWeight: 500
  },
  causalTitle: {
    fontSize: '20px',
    color: '#0A1734'
  },
  dropdownLabel: {
    fontSize: '15px',
    color: '#0D1A3A'
  },
  altTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734'
  },
  summaryTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    direction: 'ltr'
  },
  actions: {
    position: 'absolute',
    top: '-9px',
    right: '0px'
  },
  body: {
    fontSize: '20px',
    overflow: 'hidden'
  },
  actionButton: {
    color: '#000000'
  },
  disabled: {
    backgroundColor: 'transparent',
    cursor: 'default',
    pointerEvents: 'none'
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  email: {
    color: '#000000',
    fontSize: '0px'
  },
  insightRow: {
    justifyContent: 'center'
  },
  summary: {
    display: 'flex'
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#979797',
    margin: '28px 0px'
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  },
  select: {
    width: '190px',
    height: '41px',
    color: '#0D1A3A',
    marginLeft: '10px'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

/**
 * @method
 * @summary This component renders the thumbs up and down buttons and the feedback modal per causal chain
 * @name Feedback
 * @return {Object} - React JSX
 */

const Feedback = props => {
  const { data, refetch } = props;
  const feedback = data?.feedback;
  const classes = useStyles();

  const [isPromptFeedbackModalOpen, togglePromptFeedbackModalOpen] = useState(false);

  const [createFeedback, { error, data: feedbackData }] = useMutation(
    CREATE_PROMPT_TEMPLATE_FEEDBACK
  );

  useEffect(() => {
    if (feedbackData?.createPromptTemplateFeedback) {
      showToast('Feedback sent.', 'success');
      refetch();
    }
  }, [feedbackData]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const positiveFeedback = e => {
    createFeedback({
      variables: {
        input: {
          causal_chain_id: parseInt(data.id, 10),
          feedback_type: 'thumbs_up'
        }
      }
    });

    e.stopPropagation();
  };

  const negativeFeedback = e => {
    togglePromptFeedbackModalOpen(true);
    e.stopPropagation();
  };

  const isPositiveFeedback = feedback?.feedback_type === 'thumbs_up';
  const isNegativeFeedback = feedback?.feedback_type === 'thumbs_down';

  return (
    <>
      <IconButton
        className={`${classes.actionButton} ${isPositiveFeedback ? classes.disabled : ''}`}
        onClick={positiveFeedback}
      >
        {isPositiveFeedback ? <ThumbUp /> : <ThumbUpOutlined />}
      </IconButton>
      <IconButton
        className={`${classes.actionButton} ${isNegativeFeedback ? classes.disabled : ''}`}
        onClick={negativeFeedback}
      >
        {isNegativeFeedback ? <ThumbDown /> : <ThumbDownOutlined />}
      </IconButton>
      {data.id != null && isPromptFeedbackModalOpen && (
        <PromptFeedbackModal
          id={data.id}
          isModalOpen={isPromptFeedbackModalOpen}
          onChange={e => togglePromptFeedbackModalOpen(e.isModalOpen)}
          refetch={refetch}
        />
      )}
    </>
  );
};

Feedback.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    feedback: PropTypes.shape()
  }).isRequired,
  refetch: PropTypes.func
};

Feedback.defaultProps = {
  refetch: () => {}
};

/**
 * @method
 * @summary This component renders the results and justify sections for the GraphFish UI
 * @name InsightsSummary
 * @return {Object} - React JSX
 */

const InsightsSummary = props => {
  const {
    justifyData,
    terminatingEvents,
    selectedEventTypes,
    setSelectedEventTypes,
    loading,
    refetch,
    sort,
    setSort
  } = props;
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [visibleJustification, setVisibleJustification] = useState(false);

  const [isJustificationModalOpen, toggleJustificationModal] = useState(false);
  const [selectedCausalChain, setSelectedCausalChain] = useState(null);

  useEffect(() => {
    // handle re(fetching) the selected causal chain when feedback is provided in the modal
    if (isJustificationModalOpen) {
      const tempSelectedCausalChain = justifyData.find(item => item.id === selectedCausalChain.id);

      setSelectedCausalChain(tempSelectedCausalChain);
    }
    setVisibleJustification(true);
  }, [justifyData]);

  const expandJustify = (e, causalChain) => {
    setSelectedCausalChain(causalChain);
    toggleJustificationModal(true);
    e.stopPropagation();
  };

  const options = [
    { field: 'startDate', label: 'Start Date' },
    { field: 'endDate', label: 'End Date' },
    { field: 'revenue', label: 'Revenue' }
  ];

  const renderSelected = selected =>
    selected.length === terminatingEvents.length
      ? 'All'
      : selected
          .map(eventType =>
            getDrawerHeaderTitle({
              event: { type: getReadableDetectedEventTitle(eventType) }
            })
          )
          .join(', ');

  return (
    <Grid container direction="column">
      <Paper className={`${classes.box}`}>
        {loading && (
          <Box className={classes.loadingOverlay}>
            <AlbLoading />
          </Box>
        )}
        <div className={classes.title} style={{ marginBottom: '32px' }}>
          <span className={classes.causalTitle}>Causal Chains</span>
          <span>
            {terminatingEvents?.length > 0 && (
              <MuiThemeProvider theme={terminatingTheme}>
                <span className={classes.dropdownLabel} style={{ marginRight: '30px' }}>
                  Filter by Terminating Event:
                  <Select
                    className={classes.select}
                    variant="outlined"
                    multiple
                    labelId="input-label"
                    value={selectedEventTypes}
                    renderValue={renderSelected}
                    onChange={e => setSelectedEventTypes(e.target.value)}
                    IconComponent={ExpandMore}
                  >
                    {terminatingEvents.map(value => (
                      <MenuItem key={value} value={value} onClick={() => {}}>
                        <Checkbox checked={selectedEventTypes.includes(value)} />
                        {/* convert label and use drawer header as fallback */}
                        {getDrawerHeaderTitle({
                          event: { type: getReadableDetectedEventTitle(value) }
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                </span>
              </MuiThemeProvider>
            )}
            <MuiThemeProvider theme={sortByTheme}>
              <span className={classes.dropdownLabel}>
                Sort By:
                <Select
                  className={classes.select}
                  variant="outlined"
                  labelId="input-label"
                  value={sort}
                  onChange={e => setSort(e.target.value)}
                  IconComponent={ExpandMore}
                >
                  {options.map(({ field, label }) => (
                    <MenuItem key={field} value={field} onClick={() => {}}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </span>
            </MuiThemeProvider>
          </span>
        </div>

        {!loading && justifyData?.length === 0 && visibleJustification && (
          <div className={classes.altTitle} style={{ margin: '12px 0px' }}>
            There are no causal chains visible for the selected event types.
          </div>
        )}
        {justifyData?.length > 0 && visibleJustification && (
          <>
            <div style={{ opacity: loading ? 0.5 : 1, width: '100%' }}>
              <CausalAccordion justifyData={justifyData} expandJustify={expandJustify} />
            </div>

            {selectedCausalChain?.id != null && isJustificationModalOpen && (
              <CausalChainJustificationModal
                title={selectedCausalChain.title}
                justification={selectedCausalChain.justification}
                endDate={selectedCausalChain.end_date}
                isModalOpen={isJustificationModalOpen}
                onChange={e => toggleJustificationModal(e.isModalOpen)}
                feedbackComponent={
                  <Feedback
                    data={{ id: selectedCausalChain.id, feedback: selectedCausalChain.feedback }}
                    refetch={refetch}
                  />
                }
              />
            )}
          </>
        )}
      </Paper>
    </Grid>
  );
};

InsightsSummary.propTypes = {
  justifyData: PropTypes.arrayOf(PropTypes.shape()),
  terminatingEvents: PropTypes.arrayOf(PropTypes.string),
  selectedEventTypes: PropTypes.arrayOf(PropTypes.string),
  setSelectedEventTypes: PropTypes.func,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func
};

InsightsSummary.defaultProps = {
  justifyData: null,
  terminatingEvents: [],
  selectedEventTypes: [],
  setSelectedEventTypes: () => {},
  loading: false,
  refetch: () => {},
  sort: null,
  setSort: () => {}
};

export default InsightsSummary;
