import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import AlembicPageHeader from 'components/AlembicPageHeader';
import setPageTitle from 'util/titles';
import LinkGoogleAnalyticsFilterForm from './LinkGoogleAnalyticsFilterForm';

/**
 * @method
 * @summary This component renders the view that includes the form to link a Google Analytics filter
 * @name LinkGoogleAnalyticsFilter
 * @return {Object} - React JSX
 */
const LinkGoogleAnalyticsFilter = () => {
  useEffect(() => {
    setPageTitle('Link Google Analytics');
  }, []);

  return (
    <>
      <Box mb={30}>
        <AlembicPageHeader backButton pageTitle="Google Analytics" />
      </Box>

      <Paper>
        <HasAnyPriv privs={['LINKS', 'LINKS:READ', 'LINKS:READ:SELF']} showAlert>
          <Box p={24}>
            <LinkGoogleAnalyticsFilterForm />
          </Box>
        </HasAnyPriv>
      </Paper>
    </>
  );
};

export default LinkGoogleAnalyticsFilter;
