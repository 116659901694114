/* eslint-disable camelcase */
import React from 'react';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { DETECTED_EVENT_TYPE, LINKTOKEN_EVENT_TYPE } from 'util/detectedEvents';
import { renderUTCDate } from 'util/date';
import truncateUrl from 'util/truncateUrl';
import { openExternalPost } from 'util/externalLinks';
import truncateEventLabel from 'util/truncateEventLabel';

const TRUNCATE_LENGTH = 47;

/**
 * Returns single date for single events, otherwise date range for multi day events
 * @param {Object} detected event object
 * @returns a date range string
 */
const getDateRangeString = (type, startDate, endDate) => {
  return startDate === endDate
    ? renderUTCDate(startDate)
    : `${renderUTCDate(startDate)} -
                    ${renderUTCDate(endDate)}`;
};

/**
 * Returns a clickable icon directing to an external post
 * @param {string} publishedUrl - the url of the post
 * @returns - React JSX
 */
const popoutIcon = publishedUrl => (
  <IconButton
    style={{ padding: '5px', color: '#0A1734' }}
    onClick={() => openExternalPost(publishedUrl)}
  >
    <Launch />
  </IconButton>
);

/**
 * Returns the formatted Analytics events.
 * @param {Object} event event object
 * @param {Object} opt the display options
 * @param {String} displayText the text to display for the event
 * @returns - React JSX
 */
const getAnalyticsEventFormatted = (event, opt, displayText) => {
  const eventLabel = event.originalObject.event_label;
  const remoteName = event.originalObject.linktoken.remote_name;

  return (
    <Box style={{ wordBreak: 'break-word' }}>
      {displayText}{' '}
      <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
        <strong>
          {opt.truncate ? truncateEventLabel(eventLabel, TRUNCATE_LENGTH) : eventLabel}
        </strong>
      </Tooltip>{' '}
      on{' '}
      <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
        <strong>
          {opt.truncate
            ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                -TRUNCATE_LENGTH
              )}`
            : remoteName}
        </strong>
      </Tooltip>{' '}
      {getDateRangeString(event.type, event.startDate, event.endDate)}
    </Box>
  );
};

const getAppEventFormatted = (event, opt, displayText) => {
  let eventLabel = event.originalObject.eventInfo.postBody;
  const remoteName = event.originalObject.linktoken.remote_name;

  if (eventLabel === 'Desktop') {
    eventLabel = 'Mac';
  }

  return (
    <Box style={{ wordBreak: 'break-word' }}>
      {displayText} spiked for{' '}
      <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
        <strong>
          {opt.truncate
            ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                -TRUNCATE_LENGTH
              )}`
            : remoteName}
        </strong>
      </Tooltip>{' '}
      on{' '}
      <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
        <strong>
          {opt.truncate ? truncateEventLabel(eventLabel, TRUNCATE_LENGTH) : eventLabel}
        </strong>
      </Tooltip>{' '}
      {getDateRangeString(event.type, event.startDate, event.endDate)}
    </Box>
  );
};

const getAppSecondaryEventFormatted = (event, opt, displayText) => {
  const eventLabel = event.originalObject.event_label;
  let secondaryEventLabel = event.originalObject?.secondary_event_label ?? '';
  const remoteName = event.originalObject.linktoken.remote_name;

  if (secondaryEventLabel === 'Desktop') {
    secondaryEventLabel = 'Mac';
  }

  if (
    event.type === DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT ||
    event.type === DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT
  ) {
    secondaryEventLabel = 'Android';
  }

  return (
    <Box style={{ wordBreak: 'break-word' }}>
      {displayText}{' '}
      <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
        <strong>
          {opt.truncate ? truncateEventLabel(eventLabel, TRUNCATE_LENGTH) : eventLabel}
        </strong>
      </Tooltip>{' '}
      spiked for{' '}
      <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
        <strong>
          {opt.truncate
            ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                -TRUNCATE_LENGTH
              )}`
            : remoteName}
        </strong>
      </Tooltip>{' '}
      on{' '}
      <Tooltip
        title={
          opt.truncate && secondaryEventLabel.length > TRUNCATE_LENGTH ? secondaryEventLabel : ''
        }
      >
        <strong>
          {opt.truncate
            ? truncateEventLabel(secondaryEventLabel, TRUNCATE_LENGTH)
            : secondaryEventLabel}
        </strong>
      </Tooltip>{' '}
      {getDateRangeString(event.type, event.startDate, event.endDate)}
    </Box>
  );
};

const getSalesforceEventFormatted = (event, opt) => {
  const { postBody } = event.originalObject.eventInfo;
  const remoteName = event.originalObject.linktoken.remote_name;

  return (
    <Box style={{ wordBreak: 'break-word' }}>
      <Tooltip title={opt.truncate && postBody.length > TRUNCATE_LENGTH ? postBody : ''}>
        <strong>{opt.truncate ? truncateEventLabel(postBody, TRUNCATE_LENGTH) : postBody}</strong>
      </Tooltip>{' '}
      spiked for{' '}
      <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
        <strong>
          {opt.truncate
            ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                -TRUNCATE_LENGTH
              )}`
            : remoteName}
        </strong>
      </Tooltip>{' '}
      on {getDateRangeString(event.type, event.startDate, event.endDate)}
    </Box>
  );
};

/**
 * Returns the event formatted by event type
 * @param {Object} detected event object
 * @returns - React JSX
 */
const getEventFormatted = (event, opt = { hideDate: false, truncate: false, popout: false }) => {
  switch (event.type) {
    case LINKTOKEN_EVENT_TYPE.RESURGE_POST: {
      const publishedUrl = event?.campaignEvent?.published_url;

      return (
        <Box>
          Post resurgence detected. Impressions spiked on a {event.postDayLength} day old post for{' '}
          <Tooltip
            title={
              opt.truncate && event.sourceName.length > TRUNCATE_LENGTH ? event.sourceName : ''
            }
          >
            <strong>
              {opt.truncate
                ? `${event.sourceName.slice(0, TRUNCATE_LENGTH)}${
                    event.sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : event.sourceName}
            </strong>
          </Tooltip>{' '}
          {!opt.hideDate ? getDateRangeString(event.type, event.startDate, event.endDate) : ''}
          {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
        </Box>
      );
    }
    case LINKTOKEN_EVENT_TYPE.RESURGE_MULTIPOST:
      return (
        <Box>
          Multiple post resurgence detected. Impressions spiked on {event.numberOfPosts} posts for{' '}
          <Tooltip
            title={
              opt.truncate && event.sourceName.length > TRUNCATE_LENGTH ? event.sourceName : ''
            }
          >
            <strong>
              {opt.truncate
                ? `${event.sourceName.slice(0, TRUNCATE_LENGTH)}${
                    event.sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : event.sourceName}
            </strong>
          </Tooltip>{' '}
          at the same time.{' '}
          <span>
            {!opt.hideDate ? getDateRangeString(event.type, event.startDate, event.endDate) : ''}
          </span>
        </Box>
      );
    case LINKTOKEN_EVENT_TYPE.INITIAL_SURGE: {
      const publishedUrl = event?.campaignEvent?.published_url;

      return (
        <Box>
          A new post from {getDateRangeString(event.type, event.startDate, event.endDate)} on{' '}
          <Tooltip
            title={
              opt.truncate && event.sourceName.length > TRUNCATE_LENGTH ? event.sourceName : ''
            }
          >
            <strong>
              {opt.truncate
                ? `${event.sourceName.slice(0, TRUNCATE_LENGTH)}${
                    event.sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : event.sourceName}
            </strong>
          </Tooltip>{' '}
          is spiking. Virality ranking is {Math.round(event?.eventRelativeRank)}/100 compared to
          others on this account.
          {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
        </Box>
      );
    }
    case LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA3:
    case LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA4:
      return (
        <Box>
          Traffic from {truncateUrl(event.source)} on{' '}
          <Tooltip
            title={
              opt.truncate && event.sourceName.length > TRUNCATE_LENGTH ? event.sourceName : ''
            }
          >
            <strong>
              {opt.truncate
                ? `${event.sourceName.slice(0, TRUNCATE_LENGTH)}${
                    event.sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : event.sourceName}
            </strong>
          </Tooltip>{' '}
          spiked.{' '}
          <span>
            {!opt.hideDate ? getDateRangeString(event.type, event.startDate, event.endDate) : ''}
          </span>
        </Box>
      );
    case LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA3:
    case LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA4: {
      const goal = `${event?.gaGoalName?.name} (Goal #${event?.gaGoalName?.goal_id})`;

      return (
        <Box>
          Conversions on{' '}
          <Tooltip
            title={
              opt.truncate && event.sourceName.length > TRUNCATE_LENGTH ? event.sourceName : ''
            }
          >
            <strong>
              {opt.truncate
                ? `${event.sourceName.slice(0, TRUNCATE_LENGTH)}${
                    event.sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : event.sourceName}
            </strong>
          </Tooltip>{' '}
          for{' '}
          <Tooltip title={opt.truncate && goal.length > TRUNCATE_LENGTH ? goal : ''}>
            <strong>
              {opt.truncate
                ? `${goal.slice(0, TRUNCATE_LENGTH)}${goal.length > TRUNCATE_LENGTH ? '...' : ''}`
                : goal}
            </strong>
          </Tooltip>{' '}
          spiked.{' '}
          {!opt.hideDate ? getDateRangeString(event.type, event.startDate, event.endDate) : ''}
        </Box>
      );
    }
    case LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA3:
    case LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA4:
      return (
        <Box>
          The page path {truncateUrl(event.source)} on{' '}
          <Tooltip
            title={
              opt.truncate && event.sourceName.length > TRUNCATE_LENGTH ? event.sourceName : ''
            }
          >
            <strong>
              {opt.truncate
                ? `${event.sourceName.slice(0, TRUNCATE_LENGTH)}${
                    event.sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : event.sourceName}
            </strong>
          </Tooltip>{' '}
          spiked.{' '}
          {!opt.hideDate ? getDateRangeString(event.type, event.startDate, event.endDate) : ''}
        </Box>
      );
    case LINKTOKEN_EVENT_TYPE.RESURGE_MULTI_GA: // does not exist yet
      return ``;
    case LINKTOKEN_EVENT_TYPE.GENERIC_EVENT: // this is for events that don't fit -- see the metadata field to store the event type
      return ``;
    case 'CORRELATION':
      return (
        <Box>
          <strong>{event.detectedEvents.length}</strong> Events correlated{' '}
          {event.coinc_start_date === event.coinc_end_date ? 'on ' : 'between '}
          {getDateRangeString(event.type, event.coinc_start_date, event.coinc_end_date)}
        </Box>
      );
    case DETECTED_EVENT_TYPE.IM_POST_RESURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;
      const publishedUrl = event?.campaignEvent?.published_url;

      return (
        <Box>
          Post resurgence discovery detected. Impressions spiked on a {event.postDayLength} day old
          post for{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
          {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          Account resurgence detected. Impressions spiked on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;
      const publishedUrl = event?.campaignEvent?.published_url;

      return (
        <Box>
          Impressions spiked on new post on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          for the first time {getDateRangeString(event.type, event.startDate, event.endDate)}{' '}
          {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          A single viral post was associated with impressions spiking on multiple posts on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          for the first time {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.EG_POST_RESURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;
      const publishedUrl = event?.campaignEvent?.published_url;

      return (
        <Box>
          Post resurgence discovery detected. Engagement spiked on a {event.postDayLength} day old
          post for{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
          {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          Account resurgence detected. Engagement spiked on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;
      const publishedUrl = event?.campaignEvent?.published_url;

      return (
        <Box>
          Engagement spiked on new post on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          for the first time {getDateRangeString(event.type, event.startDate, event.endDate)}{' '}
          {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE: {
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          A single viral post was associated with engagement spiking on multiple posts on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          for the first time {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    // case DETECTED_EVENT_TYPE.FL_POST_RESURGE: {
    //   const remoteName = event.originalObject.linktoken.remote_name;
    //   const publishedUrl = event?.campaignEvent?.published_url;

    //   return (
    //     <Box>
    //       Post resurgence discovery detected. Followers spiked on a {event.postDayLength} day old
    //       post for{' '}
    //       <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
    //         <strong>
    //           {opt.truncate
    //             ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
    //                 -TRUNCATE_LENGTH
    //               )}`
    //             : remoteName}
    //         </strong>
    //       </Tooltip>{' '}
    //       {getDateRangeString(event.type, event.startDate, event.endDate)}
    //       {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
    //     </Box>
    //   );
    // }
    // case DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE: {
    //   const remoteName = event.originalObject.linktoken.remote_name;

    //   return (
    //     <Box>
    //       Account resurgence detected. Followers spiked on{' '}
    //       <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
    //         <strong>
    //           {opt.truncate
    //             ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
    //                 -TRUNCATE_LENGTH
    //               )}`
    //             : remoteName}
    //         </strong>
    //       </Tooltip>{' '}
    //       {getDateRangeString(event.type, event.startDate, event.endDate)}
    //     </Box>
    //   );
    // }
    // case DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE: {
    //   const remoteName = event.originalObject.linktoken.remote_name;
    //   const publishedUrl = event?.campaignEvent?.published_url;

    //   return (
    //     <Box>
    //       Followers spiked on new post on{' '}
    //       <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
    //         <strong>
    //           {opt.truncate
    //             ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
    //                 -TRUNCATE_LENGTH
    //               )}`
    //             : remoteName}
    //         </strong>
    //       </Tooltip>{' '}
    //       for the first time {getDateRangeString(event.type, event.startDate, event.endDate)}{' '}
    //       {opt.popout && publishedUrl && popoutIcon(publishedUrl)}
    //     </Box>
    //   );
    // }
    // case DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE: {
    //   const remoteName = event.originalObject.linktoken.remote_name;

    //   return (
    //     <Box>
    //       A single viral post was associated with followers spiking on multiple posts on{' '}
    //       <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
    //         <strong>
    //           {opt.truncate
    //             ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
    //                 -TRUNCATE_LENGTH
    //               )}`
    //             : remoteName}
    //         </strong>
    //       </Tooltip>{' '}
    //       for the first time {getDateRangeString(event.type, event.startDate, event.endDate)}
    //     </Box>
    //   );
    // }
    case DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Users by source spiked from');
    case DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY:
      return getAnalyticsEventFormatted(event, opt, 'Users by search term spiked from'); // this is pulling in all search traffic, including organic
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY:
      return getAnalyticsEventFormatted(event, opt, 'Total users by search term spiked from'); // this is pulling in all search traffic, including organic
    case DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD:
      return getAnalyticsEventFormatted(event, opt, 'Users by on-site search term spiked from'); // on-site
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM:
      return getAnalyticsEventFormatted(event, opt, 'Total users by search term spiked from'); // this is pulling in all search traffic, including paid
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY:
      return getAnalyticsEventFormatted(
        event,
        opt,
        'Total users by first converting paid search term spiked from'
      );
    case DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL: {
      const eventLabel =
        event.originalObject.event_label?.split(':')?.[1] ?? event.originalObject.event_label;
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          Interactions spiked for{' '}
          <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL: {
      const eventLabel =
        event.originalObject.event_label?.split(':')?.[1] ?? event.originalObject.event_label;
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          Conversions spiked for{' '}
          <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION: {
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          Session transactions spiked on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by product spiked for');
    case DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by source spiked for');
    case DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION:
      return getAnalyticsEventFormatted(event, opt, 'Revenue per transaction spiked for');
    case DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by product spiked for');
    case DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT: {
      const eventLabel = event.originalObject.eventInfo.sourceName;

      return (
        <Box>
          News/PR total mentions spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.SA_TOTAL_SHARES: {
      const eventLabel = event.originalObject.eventInfo.sourceName;

      return (
        <Box>
          Web shares spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.BC_TV_MENTIONS: {
      const eventLabel = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Linear TV total mentions spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS: {
      const eventLabel = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Linear & digital radio total mentions spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS: {
      const eventLabel = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Podcast summary total mentions spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS: {
      const eventLabel = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Podcast transcript total mentions spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP: {
      const eventLabel = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Linear TV total local Nielsen ratings spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE: {
      const eventLabel = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Podcast transcript total Podchaser ratings spiked from search{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES: {
      const eventLabel = event.originalObject?.event_label || '';
      const sourceName = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Linear TV narrative mentions spiked for narrative{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          from{' '}
          <Tooltip title={opt.truncate && sourceName.length > TRUNCATE_LENGTH ? sourceName : ''}>
            <strong>
              {opt.truncate
                ? `${sourceName.slice(0, TRUNCATE_LENGTH)}${
                    sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : sourceName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES: {
      const eventLabel = event.originalObject?.event_label || '';
      const sourceName = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Linear & digital radio narrative mentions spiked for narrative{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          from{' '}
          <Tooltip title={opt.truncate && sourceName.length > TRUNCATE_LENGTH ? sourceName : ''}>
            <strong>
              {opt.truncate
                ? `${sourceName.slice(0, TRUNCATE_LENGTH)}${
                    sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : sourceName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES: {
      const eventLabel = event.originalObject?.event_label || '';
      const sourceName = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Podcast summary narrative mentions spiked for narrative{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          from{' '}
          <Tooltip title={opt.truncate && sourceName.length > TRUNCATE_LENGTH ? sourceName : ''}>
            <strong>
              {opt.truncate
                ? `${sourceName.slice(0, TRUNCATE_LENGTH)}${
                    sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : sourceName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES: {
      const eventLabel = event.originalObject?.event_label || '';
      const sourceName = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Podcast transcript narrative mentions spiked for narrative{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          from{' '}
          <Tooltip title={opt.truncate && sourceName.length > TRUNCATE_LENGTH ? sourceName : ''}>
            <strong>
              {opt.truncate
                ? `${sourceName.slice(0, TRUNCATE_LENGTH)}${
                    sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : sourceName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL: {
      const eventLabel = event.originalObject?.event_label || '';
      const sourceName = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Linear TV narrative local Nielsen ratings spiked for narrative{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          from{' '}
          <Tooltip title={opt.truncate && sourceName.length > TRUNCATE_LENGTH ? sourceName : ''}>
            <strong>
              {opt.truncate
                ? `${sourceName.slice(0, TRUNCATE_LENGTH)}${
                    sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : sourceName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL: {
      const eventLabel = event.originalObject?.event_label || '';
      const sourceName = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Linear TV narrative national Nielsen ratings spiked for narrative{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          from{' '}
          <Tooltip title={opt.truncate && sourceName.length > TRUNCATE_LENGTH ? sourceName : ''}>
            <strong>
              {opt.truncate
                ? `${sourceName.slice(0, TRUNCATE_LENGTH)}${
                    sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : sourceName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS: {
      const eventLabel = event.originalObject?.event_label || '';
      const sourceName = event.originalObject?.eventInfo?.sourceName || '';

      return (
        <Box>
          Podcast transcript narrative Podchaser ratings spiked for narrative{' '}
          <Tooltip title={opt.truncate && eventLabel?.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
                    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          from{' '}
          <Tooltip title={opt.truncate && sourceName.length > TRUNCATE_LENGTH ? sourceName : ''}>
            <strong>
              {opt.truncate
                ? `${sourceName.slice(0, TRUNCATE_LENGTH)}${
                    sourceName.length > TRUNCATE_LENGTH ? '...' : ''
                  }`
                : sourceName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER: {
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          Purchaser transactions spiked on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.AA_UNIQUE_VISITORS: {
      const remoteName = event.originalObject.linktoken.remote_name;

      return (
        <Box>
          Unique users spiked on{' '}
          <Tooltip title={opt.truncate && remoteName.length > TRUNCATE_LENGTH ? remoteName : ''}>
            <strong>
              {opt.truncate
                ? `${remoteName.length > TRUNCATE_LENGTH ? '...' : ''}${remoteName.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : remoteName}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.NS_FUND_FLOW: {
      const eventLabel = event.originalObject.event_label;

      return (
        <Box>
          Fund flow spiked for ticker{' '}
          <Tooltip title={opt.truncate && eventLabel.length > TRUNCATE_LENGTH ? eventLabel : ''}>
            <strong>
              {opt.truncate
                ? `${eventLabel.length > TRUNCATE_LENGTH ? '...' : ''}${eventLabel.slice(
                    -TRUNCATE_LENGTH
                  )}`
                : eventLabel}
            </strong>
          </Tooltip>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.SL_CLICKS: {
      return (
        <Box>
          Clicks spiked on shortlink{' '}
          <strong>https://albc.us/{event.originalObject.eventInfo.postBody}</strong> for campaign{' '}
          <strong>{event.originalObject.eventInfo.sourceName}</strong>{' '}
          {getDateRangeString(event.type, event.startDate, event.endDate)}
        </Box>
      );
    }
    case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by item name spiked for');
    case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by session source spiked for');
    case DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by item name spiked for');
    case DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL:
      return getAnalyticsEventFormatted(event, opt, 'Interactions spiked for');
    case DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL:
      return getAnalyticsEventFormatted(event, opt, 'Conversions spiked for');
    case DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH:
      return getAnalyticsEventFormatted(event, opt, 'Users spiked on page path');
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Total users spiked from');
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH:
      return getAnalyticsEventFormatted(event, opt, 'Total users spiked on page path');
    case DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Total users spiked from');
    case DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH:
      return getAnalyticsEventFormatted(event, opt, 'Users spiked on page path');
    case DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD:
      return getAnalyticsEventFormatted(event, opt, 'Users by organic search term spiked from');
    case DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH:
      return getAnalyticsEventFormatted(event, opt, 'Users by paid search term spiked from');
    case DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Interactions spiked for'
      );
    case DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Conversions spiked for'
      );
    case DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Revenue by source spiked for source'
      );
    case DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Revenue by campaign spiked for campaign'
      );
    case DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Revenue by channel spiked for channel'
      );
    case DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Revenue by fare type spiked for fare type'
      );
    case DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Transactions by campaign spiked for campaign'
      );
    case DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Transactions by channel spiked for channel'
      );
    case DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Transactions by fare type spiked for fare type'
      );
    case DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Quantity by campaign spiked for campaign'
      );
    case DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Quantity by channel spiked for channel'
      );
    case DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'Quantity by fare type spiked for fare type'
      );
    case DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'SkyMiles used by campaign spiked for campaign'
      );
    case DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'SkyMiles used by channel spiked for channel'
      );
    case DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE:
      return getAnalyticsEventFormatted(
        event,
        { ...opt, truncate: true },
        'SkyMiles used by fare type spiked for fare type'
      );
    case DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING:
      return getAnalyticsEventFormatted(event, opt, 'Users by channel spiked for');
    case DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Users by campaign spiked for');
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING:
      return getAnalyticsEventFormatted(event, opt, 'Total users by channel spiked for');
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME:
      return getAnalyticsEventFormatted(event, opt, 'Total users by campaign spiked for');
    case DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by channel spiked for');
    case DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by campaign spiked for');
    case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by channel spiked for');
    case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by campaign spiked for');
    case DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH:
      return getAnalyticsEventFormatted(event, opt, 'Users by on-site search term spiked from');
    case DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL:
      return getAnalyticsEventFormatted(event, opt, 'Users by channel spiked for');
    case DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Users by campaign spiked for');
    case DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Bookings by campaign spiked for');
    case DETECTED_EVENT_TYPE.AA_BOOKINGS_BY_MARKETING_CHANNEL:
      return getAnalyticsEventFormatted(event, opt, 'Bookings by channel spiked for');
    case DETECTED_EVENT_TYPE.AA_CALLS_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Calls by campaign spiked for');
    case DETECTED_EVENT_TYPE.AA_CALLS_BY_MARKETING_CHANNEL:
      return getAnalyticsEventFormatted(event, opt, 'Calls by channel spiked for');
    case DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD:
      return getAnalyticsEventFormatted(event, opt, 'Users by search term spiked from');
    case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM:
      return getAnalyticsEventFormatted(
        event,
        opt,
        'Total users by on-site search term spiked from'
      );
    case DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE:
    case DETECTED_EVENT_TYPE.GO_INSTALLS:
      return getAppEventFormatted(event, opt, 'Installs');
    case DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS:
      return getAppEventFormatted(event, opt, 'Active devices');
    case DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS:
      return getAppEventFormatted(event, opt, 'Uninstalls');
    case DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE:
    case DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT:
      return getAppSecondaryEventFormatted(event, opt, 'Purchases of');
    case DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE:
    case DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT:
      return getAppSecondaryEventFormatted(event, opt, 'Revenue from');
    case DETECTED_EVENT_TYPE.SF_LEADS_LEADS:
    case DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES:
      return getSalesforceEventFormatted(event, opt);
    case DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Leads by source spiked for');
    case DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM:
      return getAnalyticsEventFormatted(event, opt, 'Leads by program spiked for');
    case DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Opportunities by source spiked for');
    case DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by source spiked for');
    case DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(
        event,
        opt,
        'Opportunities from leads by campaign spiked for'
      );
    case DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Opportunities by campaign spiked for');
    case DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by store spiked for');
    case DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by channel spiked for');
    case DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by product spiked for');
    case DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by loyalty program spiked for');
    case DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by primary discount code spiked for');
    case DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2:
      return getAnalyticsEventFormatted(
        event,
        opt,
        'Revenue by secondary discount code spiked for'
      );
    case DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE:
      return getAnalyticsEventFormatted(event, opt, 'Transactions by store spiked for');
    case DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL:
      return getAnalyticsEventFormatted(event, opt, 'Transactions by channel spiked for');
    case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by product spiked for');
    case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by loyalty program spiked for');
    case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by primary discount code spiked for');
    case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2:
      return getAnalyticsEventFormatted(
        event,
        opt,
        'Quantity by secondary discount code spiked for'
      );
    case DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE:
      return getAnalyticsEventFormatted(event, opt, 'Foot traffic by state spiked for state');
    case DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_STATE:
      return getAnalyticsEventFormatted(event, opt, 'Appointments by state spiked for');
    case DETECTED_EVENT_TYPE.ME_APPOINTMENTS_BY_SEGMENT:
      return getAnalyticsEventFormatted(event, opt, 'Appointments by segment spiked for');
    case DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_STATE:
      return getAnalyticsEventFormatted(event, opt, 'Opportunities by state spiked for');
    case DETECTED_EVENT_TYPE.ME_OPPORTUNITIES_BY_SEGMENT:
      return getAnalyticsEventFormatted(event, opt, 'Opportunities by segment spiked for');
    case DETECTED_EVENT_TYPE.ME_REVENUE_BY_STATE:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by state spiked for');
    case DETECTED_EVENT_TYPE.ME_REVENUE_BY_SEGMENT:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by segment spiked for');
    case DETECTED_EVENT_TYPE.ME_QUANTITY_BY_STATE:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by state spiked for');
    case DETECTED_EVENT_TYPE.ME_QUANTITY_BY_SEGMENT:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by segment spiked for');
    case DETECTED_EVENT_TYPE.RE_REVENUE_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by campaign spiked for');
    case DETECTED_EVENT_TYPE.RE_REVENUE_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by source spiked for');
    case DETECTED_EVENT_TYPE.RE_REVENUE_BY_INVESTMENT_INDUSTRY:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by investment industry spiked for');
    case DETECTED_EVENT_TYPE.RE_REVENUE_BY_INVESTOR_TYPE:
      return getAnalyticsEventFormatted(event, opt, 'Revenue by investor type spiked for');
    case DETECTED_EVENT_TYPE.RE_QUANTITY_BY_CAMPAIGN:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by campaign spiked for');
    case DETECTED_EVENT_TYPE.RE_QUANTITY_BY_SOURCE:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by source spiked for');
    case DETECTED_EVENT_TYPE.RE_QUANTITY_BY_INVESTMENT_INDUSTRY:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by investment industry spiked for');
    case DETECTED_EVENT_TYPE.RE_QUANTITY_BY_INVESTOR_TYPE:
      return getAnalyticsEventFormatted(event, opt, 'Quantity by investor type spiked for');
    default:
      return ``;
  }
};

export default getEventFormatted;
