import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { CalendarToday, ArrowLeft, ArrowRight } from '@material-ui/icons';
import Box from 'components/Box';

const style = makeStyles({
  root: {
    minWidth: 0,
    padding: '2px 12px',
    justifyContent: 'center'
  },
  calendar: {
    height: '40px'
  },
  range: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  startIcon: {
    fontSize: '12px',
    marginRight: '5px'
  },
  calendarButton: {
    minWidth: '180px',
    borderRadius: '0px',
    margin: '0px 1px !important'
  },
  prevButton: {
    borderRadius: '5.5px 0px 0px 5.5px',
    margin: '0px !important'
  },
  nextButton: {
    borderRadius: '0px 5.5px 5.5px 0px',
    margin: '0px !important'
  }
});

const RangePicker = ({
  range,
  selectedRange,
  callback,
  setDateRange,
  delta,
  singleDate,
  customArrowRange,
  allowStartAfterCurrentDate
}) => {
  const { start, end } = range;
  const { fixed, rangeKey, offset, offsetKey, modulus } = selectedRange;

  const classes = style();

  const [fixedDate, setFixedDate] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const endDate = moment(end).startOf('d');
    const currentDate = moment().startOf('d');

    if (delta) {
      currentDate.subtract(delta ? 1 : 0, 'd');
    }

    const isAfterCurrentDate = endDate.isAfter(currentDate);
    const isCurrentDate = endDate.isSame(currentDate);

    if (!allowStartAfterCurrentDate) {
      setDisabled(isAfterCurrentDate || isCurrentDate);
    }
  }, [end]);

  useEffect(() => {
    setFixedDate(!!fixed);
  }, [selectedRange]);

  const formatDate = date => moment(date).format('MMM D, YYYY');

  const d = moment(start).diff(moment(end), 'd') - 1;

  const getRange = (field, action, time) =>
    moment(field)
      [action](1, rangeKey)
      [time](rangeKey)
      .add(modulus ? offset % modulus : offset, offsetKey)
      .toDate();

  const getDifference = (field, action) => {
    if (customArrowRange) {
      const customD = `-${customArrowRange}`;

      return moment(field)
        [action]({ d: customD })
        .toDate();
    }

    return moment(field)
      [action]({ d })
      .toDate();
  };

  const setPrevRange = () => {
    const prevRange = fixedDate
      ? {
          start: getRange(start, 'subtract', 'startOf'),
          end: getRange(start, 'subtract', 'endOf')
        }
      : { start: getDifference(start, 'add'), end: getDifference(end, 'add') };

    setDateRange(prevRange);
  };

  const setNextRange = () => {
    const nextRange = fixedDate
      ? { start: getRange(start, 'add', 'startOf'), end: getRange(start, 'add', 'endOf') }
      : { start: getDifference(start, 'subtract'), end: getDifference(end, 'subtract') };

    const { end: endDate, start: startDate } = nextRange;
    const currentDate = moment();

    if (delta) {
      currentDate.subtract(delta ? 1 : 0, 'd');
    }

    const startIsAfterCurrentDate = moment(startDate).isAfter(currentDate);
    const endIsAfterCurrentDate = moment(endDate).isAfter(currentDate);

    // disallow traversing a time frame past the current day
    if (startIsAfterCurrentDate && !allowStartAfterCurrentDate) {
      nextRange.start = currentDate.toDate();
    }

    if (endIsAfterCurrentDate && !allowStartAfterCurrentDate) {
      nextRange.end = currentDate.toDate();
    }

    setDateRange(nextRange);
  };

  return (
    <Box mb={10}>
      <Grid container wrap="nowrap" className={classes.calendar}>
        <Button
          color="primary"
          variant="contained"
          className={classes.range}
          classes={{
            root: `${classes.root} ${classes.prevButton}`
          }}
          onClick={setPrevRange}
        >
          <ArrowLeft />
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.range}
          classes={{
            root: `${classes.root} ${classes.calendarButton}`
          }}
          onClick={callback}
        >
          <CalendarToday className={classes.startIcon} />
          {singleDate ? (
            <>{formatDate(range.start)}</>
          ) : (
            `${formatDate(range.start)} - ${formatDate(range.end)}`
          )}
        </Button>
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          className={classes.range}
          classes={{
            root: `${classes.root} ${classes.nextButton}`
          }}
          onClick={setNextRange}
        >
          <ArrowRight />
        </Button>
      </Grid>
    </Box>
  );
};

RangePicker.propTypes = {
  range: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
  }).isRequired,
  selectedRange: PropTypes.shape(),
  callback: PropTypes.func,
  setDateRange: PropTypes.func,
  delta: PropTypes.bool,
  singleDate: PropTypes.bool,
  customArrowRange: PropTypes.string,
  allowStartAfterCurrentDate: PropTypes.bool
};

RangePicker.defaultProps = {
  selectedRange: {},
  callback: null,
  setDateRange: null,
  delta: false,
  singleDate: false,
  customArrowRange: null,
  allowStartAfterCurrentDate: false
};

export default RangePicker;
